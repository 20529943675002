import React, {useState, useRef, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import {
    Route,
    Routes
} from "react-router-dom"
import EventExcerptTitlePage from "../Event/EventExcerptTitlePage"
import ReturnToCategory from '../Subnav/ReturnToCategory'
import ReturnToShow from '../Subnav/ReturnToShow'
import ReturnToMedia from '../Subnav/ReturnToMedia'
import TitleMediaSingle from './TitleMedia/TitleMediaSingle'
import MediaSectionComponent from './TitleMedia/MediaSection/MediaSection'
import YouTube from 'react-youtube'
import CompetitionsAwards from './CompetitionsAwards/CompetitionsAwards'
import CompetitionsAwardsColumns from './CompetitionsAwards/CompetitionsAwardsColumns'
import Sidebar from './Sidebar/Sidebar'
import {Helmet} from 'react-helmet'
import scriptPlaceholder from '../../Common/_assets/img/bg/script-blank.png'
// import videoPlaceholder from '../../Common/_assets/img/placeholder/videoplaceholderblank.gif'
import {getShowDetailPageData, getShowAwards} from "../../utils/api/helpers/ShowDetail";
import {getShowMediaByTag} from "../../utils/api/helpers/Media";
import {listVideosByShowSlug} from "../../utils/api/helpers/video";
import {getEventsByShowSlug} from "../../utils/api/helpers/event";


const TitleDetail = () => {

    const {categorySlug,titleSlug,mediaSection} = useParams()

    const videoFullRef = useRef()
    const featuredVideoFullRef = useRef()

    const [titleId,setTitleId] = useState(""),
        [titleName,setTitleName] = useState(""),
        [titleBGImage,setTitleBGImage] = useState(null),
        [titleTTImage,setTitleTTImage] = useState(null),
        [scrolled, setScrolled] = useState(false),
        [socialIG, setSocialIG] = useState(),
        [socialTW, setSocialTW] = useState(),
        [socialFB, setSocialFB] = useState(),
        [reviewQuote, setReviewQuote] = useState(null),
        [reviewCredit, setReviewCredit] = useState(null),
        [viewingLink, setViewingLink] = useState(null),
        [sagIneligible, setSagIneligible] = useState(false),
        [trailerLoaded, setTrailerLoaded] = useState(false),
        [trailerId, setTrailerId] = useState(null),
        [availableOn, setAvailableOn] = useState(null),
        [competitions, setCompetitions] = useState(null),
        [hasAwards, setHasAwards] = useState(false),
        [hasVideos, setHasVideos] = useState(false),
        [hasEvents, setHasEvents] = useState(false),
        [hasImages, setHasImages] = useState(false),
        [hasPresentations, setHasPresentations] = useState(false),
        [hasContent, setHasContent] = useState(false),
        [taglist_videos, set_taglist_videos] = useState(null),
        [fyseeVideoList, setFyseeVideoList] = useState(null),
        [hasMediaRead, setHasMediaRead] = useState(false),
        [scripts, setScripts] = useState(null),
        [taglist_images, set_taglist_images] = useState(null),
        [taglist_presentations, set_taglist_presentations] = useState(null),
        [taglist_press, set_taglist_press] = useState(null),
        [taglist_press_videos, set_taglist_press_videos] = useState(null),
        [hasAudio, setHasAudio] = useState(false),
        [taglist_mp3single, set_taglist_mp3single] = useState(null),
        [taglist_mp3album, set_taglist_mp3album] = useState(null),
        [taglist_spotify, set_taglist_spotify] = useState(null),
        [links, setLinks] = useState( null ),
        [secondaryLinks, setSecondaryLinks] = useState( null ),
        [hasLinks, setHasLinks] = useState( false ),
        [award_count, set_award_count] = useState( null ),
        [eventList,setEventList] = useState(null)

    const vidOpts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
            controls: 1,
            cc_load_policy: 0,
            fs: 1,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
            playsinline:1
        },
    }
    const onVideoFullEnd = () => {
        videoFullRef.current.internalPlayer.stopVideo()
        setTimeout(()=>{
            setTrailerLoaded(false)
        }, 500)
    }
    const onVideoReady = () => {
        if (videoFullRef.current)
            videoFullRef.current.internalPlayer.playVideo()
    }
    const playVideo = () => {
        if (videoFullRef.current)
            videoFullRef.current.internalPlayer.playVideo()
    }
    const stopVideo = () => {
        if (videoFullRef.current)
            videoFullRef.current.internalPlayer.stopVideo()
    }

    const stopMP4Video = () => {
        if (featuredVideoFullRef.current) {
            featuredVideoFullRef.current.pause()
            featuredVideoFullRef.current.currentTime = 0
        }

    }



    const s3Url = 'https://jwlfyc-public-us-east-1.s3.amazonaws.com/'

    useEffect( () => {

        const trailerTimeout = ()=>{
            if (!mediaSection) {
                setTimeout(()=>{
                    setTrailerLoaded(true)
                    playVideo()
                }, 1000)
            }
        }

        getShowDetailPageData(categorySlug, titleSlug)
            .then(result => {
                // console.log("Show Detail", result)
                if (result.data && result.status===200 && result.data.title) {
                    setScrolled(false)
                            setTitleName(result.data.title)
                            setSocialTW(result.data.twitter ? result.data.twitter : null)
                            setSocialFB(result.data.facebook ? result.data.facebook : null)
                            setSocialIG(result.data.instagram ? result.data.instagram : null)
                            setViewingLink(result.data.viewing_link)
                            setReviewQuote(result.data.review_quote)
                            setReviewQuote(result.data.review_quote)
                            setReviewCredit(result.data.review_credit)

                            let avail_on = result.data.available_on ? result.data.available_on.split('T')[0] : null;
                            setAvailableOn(avail_on)

                            setSagIneligible(result.data.sag_ineligible)

                            if (result.data.trailer_id) {
                              setTrailerId(result.data.trailer_id)
                              trailerTimeout()
                            }

                            setTitleTTImage(s3Url+result.data.titleTreatment)
                            setTitleBGImage(s3Url+result.data.backgroundImage)
                            setTitleId(0)
                            set_taglist_mp3single(null)
                            set_taglist_mp3album(null)
                            set_taglist_spotify(null)
                            set_taglist_images(null)
                            set_taglist_presentations(null)
                            // set_award_count(1)
                            setHasAudio(false)
                            setHasImages(false)
                            // setHasMediaRead(false)
                            // setScripts(null)
                            setHasLinks(false)
                            setLinks(null)
                            setSecondaryLinks(null)
                } else {
                    window.location.href="/"
                }
            }).catch(() => {});
    }, [categorySlug, titleSlug, mediaSection])

    useEffect( () => {
        getShowAwards(categorySlug, titleSlug)
            .then(result => {
                // console.log("Show Awards", result)
                if (result && result.data && Object.keys(result.data).length>0) {
                    setCompetitions(result.data)
                    if (result.data[0].group.length>0
                    || result.data[0].individual.length>0
                    || result.data[0].program.length>0) {
                        setHasAwards(true)
                        set_award_count(result.data[0].group.length + result.data[0].program.length + result.data[0].individual.length)
                        // set_award_count(1)
                        // console.log(result.data[0].group.length, result.data[0].program.length, result.data[0].individual.length)
                    }

                }
            }).catch(() => {});
    }, [categorySlug, titleSlug])

    useEffect( () => {
        getShowMediaByTag(categorySlug, titleSlug, 'script')
            .then(result => {
                // console.log("Media by tag", result)
                if (result && result.data && result.status===200 && Object.keys(result.data).length>0) {
                    setScripts(result.data.reverse())
                    setHasMediaRead(true)
                    setHasContent(true)
                }
            }).catch(() => {});
    }, [categorySlug, titleSlug])

    useEffect( () => {
        getShowMediaByTag(categorySlug, titleSlug, 'video')
            .then(result => {
                // console.log("Media by tag", result)
                if (result && result.data && result.status===200 && Object.keys(result.data).length>0) {
                        set_taglist_videos(result.data)
                        setHasVideos(true)
                        setHasContent(true)
                }
            }).catch(() => {});
    }, [categorySlug, titleSlug])

    useEffect( () => {
        getShowMediaByTag(categorySlug, titleSlug, 'press')
            .then(result => {
                if (result && result.data && result.status===200 && Object.keys(result.data).length>0) {
                    // console.log("Media by tag press", result)
                    set_taglist_press(result.data)
                    setHasContent(true)
                }
            }).catch(() => {});
    }, [categorySlug, titleSlug])

    useEffect( () => {
        getShowMediaByTag(categorySlug, titleSlug, 'press-video')
            .then(result => {
                if (result && result.data && result.status===200 && Object.keys(result.data).length>0) {
                    // console.log("set_taglist_press_videos", result.data)
                    set_taglist_press_videos(result.data)
                    setHasContent(true)
                }
            }).catch(() => {});
    }, [categorySlug, titleSlug])

    useEffect( () => {
        getShowMediaByTag(categorySlug, titleSlug, 'design-presentations')
            .then(result => {
                if (result && result.data && result.status===200 && Object.keys(result.data).length>0) {
                    // console.log("set_taglist_press_videos", result.data)
                    set_taglist_presentations(result.data)
                    setHasContent(true)
                    setHasPresentations(true)
                }
            }).catch(() => {});
    }, [categorySlug, titleSlug])

    useEffect( () => {
        listVideosByShowSlug(titleSlug)
            .then(result => {
                // console.log("Video", result)
                if (result && result.data && result.status===200 && Object.keys(result.data).length>0) {
                    setFyseeVideoList(result.data)
                    setHasVideos(true)
                    setHasContent(true)
                }
            }).catch(() => {});
    }, [titleSlug])

    useEffect( () => {
        getEventsByShowSlug(titleSlug)
            .then(result => {
                // console.log("Events", result)
                if (result && result.data && result.status===200 && Object.keys(result.data[0]).length>0) {
                    setEventList(result.data[0])
                    setHasEvents(true)
                    setHasContent(true)
                }
            }).catch(() => {});
    }, [titleSlug])


    return (
        <div className="d-flex flex-md-fill">

            <Helmet>
                <title>Netflix FYC | Series - {titleName?titleName:''}</title>
            </Helmet>



            <Routes>

                <Route path={'/'} element={
                    <>
                        <div className="subnav__container--absolute d-flex align-items-center">
                            <ReturnToCategory />
                        </div>
                        <div className="content__container d-flex flex-md-fill ">
                            <div className="width_ctrl row no-gutters flex-md-fill">

                                <Sidebar award_count={award_count}
                                       titleName={titleName}
                                        titleId={titleId}
                                        titleSlug={titleSlug}
                                        titleTTImage={titleTTImage}
                                        trailerId={trailerId}
                                        trailerLoaded={trailerLoaded}
                                        setTrailerLoaded={setTrailerLoaded}
                                        stopVideo={stopVideo}
                                        stopMP4Video={stopMP4Video}
                                        playVideo={playVideo}
                                        socialFB={socialFB}
                                        socialTW={socialTW}
                                        socialIG={socialIG}
                                        sagIneligible={sagIneligible}
                                        reviewQuote={reviewQuote}
                                        reviewCredit={reviewCredit}
                                        viewingLink={viewingLink}
                                        availableOn={availableOn}
                                        hasAwards={hasAwards}
                                        competitions={competitions}
                                        hasImages={hasImages}
                                        hasAudio={hasAudio}
                                        hasEvents={hasEvents}
                                        hasMediaRead={hasMediaRead}
                                        hasVideos={hasVideos}
                                        hasLinks={hasLinks} />

                                <div className={"main__content pt-5 pt-md-0 col-12 col-md-8 d-flex ps-0 ps-md-5 pe-0 flex-column align-items-start justify-content-center"}>

                                    <div className="pe-0 pe-md-4 scroll-container" style={{
                                        maxHeight:'85vh',
                                        overflowY:hasContent?'scroll':'visible',
                                        width:'100%',
                                        transform:hasContent?'none':'translateY(-2rem)'
                                    }}>

                                        { trailerId ?

                                            <div className={"d-block titlepage__trailer--container w-100 "+ (trailerLoaded ? "active" : "")}>

                                                <div onClick={()=>{
                                                  stopVideo()
                                                  setTrailerLoaded(false)
                                                }} className="pb-3 text-uppercase text-right" style={{fontSize:'.8rem',cursor:'pointer'}}>
                                                  <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-2"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                                  <span>Close Trailer</span>
                                                </div>

                                                <div className="video__container">
                                                  <YouTube
                                                    id="trailer_video"
                                                    className="videofull__video"
                                                    ref={videoFullRef}
                                                    onReady={onVideoReady}
                                                    onEnd={onVideoFullEnd}
                                                    videoId={trailerId}
                                                    opts={vidOpts}
                                                    style={{zIndex:5}} />
                                                </div>
                                            </div>

                                            : null
                                        }


                                        {
                                            hasContent ?
                                                <div className={"mt-4 mb-5 mb-md-0"}>
                                                    <div
                                                        className={"d-flex flex-column flex-md-row flex-md-wrap align-items-start justify-content-start"}>

                                                        {
                                                            hasPresentations && taglist_presentations && (titleSlug==='avatar-the-last-airbender') ?

                                                                <div className={"col-12 col-md-6 mb-4"}>
                                                                    <div className={"titledetail__feed-container"}>
                                                                        <div className={"titledetail__feed-title mb-3"}>DESIGN PRESENTATIONS</div>
                                                                        <div className={"d-flex flex-wrap flex-row"}>
                                                                            {taglist_presentations.map((o, i) => {
                                                                                return (
                                                                                    <a href={'/'+categorySlug+'/'+titleSlug+'/presentations/'+o.slug} key={i} className={"d-block titledetail__feed-item mb-3 pe-3 text-white "+((hasEvents||hasVideos)?'col-12':'col-12 col-md-6')}>
                                                                                        <div className="d-flex align-items-start justify-content-start">
                                                                                            <div className="col-3 d-flex flex-column align-items-start justify-content-center">
                                                                                                <img src={s3Url+o.thumbnail} alt="NF" style={{pointerEvents:'none',backgroundColor:'rgba(255,255,255, 0.05)'}}/>
                                                                                            </div>
                                                                                            <div className="ps-3 col-9" >
                                                                                                <div className="d-block  mb-2"  style={{lineHeight:'1.3em',fontSize:'.9rem'}}>{o.title}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            : null
                                                        }

                                                        {
                                                            hasEvents ?
                                                                <div className={"col-12 mb-4"}>
                                                                    <div className={"titledetail__feed-container"}>
                                                                        <div
                                                                            className={"titledetail__feed-title mb-3"}>EVENTS
                                                                        </div>
                                                                        <div
                                                                            className={"titledetail__feed-item titledetail__event--container d-flex"}>

                                                                            {
                                                                                eventList ?
                                                                                    eventList.map((o, i) => {
                                                                                        return <EventExcerptTitlePage
                                                                                            key={i} event_object={o}/>
                                                                                    })
                                                                                    : null
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }

                                                        {
                                                            hasVideos && fyseeVideoList && (titleSlug === 'baby-reindeer') ?

                                                                <div className={"col-12 mb-4 "}>
                                                                    <div className={"titledetail__feed-container"}>
                                                                        <div
                                                                            className={"titledetail__feed-title mb-3"}>FYSEE
                                                                            TV
                                                                        </div>
                                                                        <div className={"d-flex flex-wrap flex-row"}>
                                                                            {fyseeVideoList.map((o, i) => {
                                                                                return (
                                                                                    <a href={'/' + categorySlug + '/' + titleSlug + '/videos/' + o.slug}
                                                                                       key={i}
                                                                                       className={"d-block titledetail__feed-item mb-3 pe-3 text-white 'col-12 col-md-6"}>
                                                                                        <div
                                                                                            className="d-flex align-items-start justify-content-start">
                                                                                            <div
                                                                                                className="col-3 d-flex flex-column align-items-start justify-content-center">
                                                                                                <img
                                                                                                    src={s3Url + o.thumbnail}
                                                                                                    alt="NF" style={{
                                                                                                    pointerEvents: 'none',
                                                                                                    backgroundColor: 'rgba(255,255,255, 0.05)'
                                                                                                }}/>
                                                                                            </div>
                                                                                            <div className="ps-3 col-9">
                                                                                                <div
                                                                                                    className="d-block  mb-2"
                                                                                                    style={{
                                                                                                        lineHeight: '1.3em',
                                                                                                        fontSize: '.9rem'
                                                                                                    }}>{o.title}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                : null
                                                        }



                                                        {
                                                            taglist_press || taglist_press_videos ?
                                                                <div className={"col-12 mb-3"}>
                                                                    <div className={"titledetail__feed-container"}>
                                                                        <div
                                                                            className={"titledetail__feed-title mb-3"}>PRESS
                                                                        </div>
                                                                        <div className={"d-flex flex-wrap flex-row"}>

                                                                            {taglist_press ? taglist_press.map((o, i) => {
                                                                                return (
                                                                                    <a key={i} href={o.url}
                                                                                       className={"d-block titledetail__feed-item mb-3 pe-3 text-white col-12 col-md-6"}
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer">
                                                                                        <div
                                                                                            className="d-flex align-items-start justify-content-start">
                                                                                            <div
                                                                                                className="col-3 d-flex flex-column align-items-start justify-content-center">
                                                                                                <img
                                                                                                    src={s3Url+o.filename}
                                                                                                    alt="NF" style={{
                                                                                                    pointerEvents: 'none',
                                                                                                    backgroundColor: 'rgba(255,255,255, 0.05)'
                                                                                                }}/>
                                                                                            </div>
                                                                                            <div className="ps-3 col-9">
                                                                                                <div
                                                                                                    className="d-block  mb-1"
                                                                                                    style={{
                                                                                                        lineHeight: '1.3em',
                                                                                                        fontSize: '.9rem'
                                                                                                    }}>{o.title}</div>
                                                                                                <div
                                                                                                    className="d-block  mb-2 "
                                                                                                    style={{
                                                                                                        lineHeight: '1.3em',
                                                                                                        fontSize: '.7rem',
                                                                                                        color: '#999'
                                                                                                    }} dangerouslySetInnerHTML={{__html: o.description}} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            }) : null}

                                                                            {taglist_press_videos ? taglist_press_videos.map((o, i) => {
                                                                                return (
                                                                                    <a href={'/' + categorySlug + '/' + titleSlug + '/videos/' + o.slug}
                                                                                       key={i}
                                                                                       className={"d-block titledetail__feed-item mb-3 pe-3 text-white 'col-12 col-md-6"}>
                                                                                        <div
                                                                                            className="d-flex align-items-start justify-content-start">
                                                                                            <div
                                                                                                className="col-3 d-flex flex-column align-items-start justify-content-center">
                                                                                                <img
                                                                                                    src={s3Url + o.thumbnail}
                                                                                                    alt="NF" style={{
                                                                                                    pointerEvents: 'none',
                                                                                                    backgroundColor: 'rgba(255,255,255, 0.05)'
                                                                                                }}/>
                                                                                            </div>
                                                                                            <div className="ps-3 col-9">
                                                                                                <div
                                                                                                    className="d-block  mb-2"
                                                                                                    style={{
                                                                                                        lineHeight: '1.3em',
                                                                                                        fontSize: '.9rem'
                                                                                                    }}>{o.title}</div>
                                                                                                <div
                                                                                                    className="d-block  mb-2 "
                                                                                                    style={{
                                                                                                        lineHeight: '1.3em',
                                                                                                        fontSize: '.7rem',
                                                                                                        color: '#999'
                                                                                                    }}
                                                                                                    dangerouslySetInnerHTML={{__html: o.description}}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            }) : null}


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }



                                                        {
                                                            hasVideos && taglist_videos ?

                                                                <div className={"col-12 mb-4 "}>
                                                                    <div className={"titledetail__feed-container"}>
                                                                        <div
                                                                            className={"titledetail__feed-title mb-3"}>VIDEOS
                                                                        </div>
                                                                        <div className={"d-flex flex-wrap flex-row"}>
                                                                            {taglist_videos.map((o, i) => {
                                                                                return (
                                                                                    <a href={'/' + categorySlug + '/' + titleSlug + '/videos/' + o.slug}
                                                                                       key={i}
                                                                                       className={"d-block titledetail__feed-item mb-3 pe-3 text-white 'col-12 col-md-6"}>
                                                                                        <div
                                                                                            className="d-flex align-items-start justify-content-start">
                                                                                            <div
                                                                                                className="col-3 d-flex flex-column align-items-start justify-content-center">
                                                                                                <img
                                                                                                    src={s3Url + o.thumbnail}
                                                                                                    alt="NF" style={{
                                                                                                    pointerEvents: 'none',
                                                                                                    backgroundColor: 'rgba(255,255,255, 0.05)'
                                                                                                }}/>
                                                                                            </div>
                                                                                            <div className="ps-3 col-9">
                                                                                                <div
                                                                                                    className="d-block  mb-2"
                                                                                                    style={{
                                                                                                        lineHeight: '1.3em',
                                                                                                        fontSize: '.9rem'
                                                                                                    }}>{o.title}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                : null
                                                        }

                                                        {
                                                            hasVideos && fyseeVideoList && (titleSlug !== 'baby-reindeer') ?

                                                                <div className={"col-12 mb-4 "}>
                                                                    <div className={"titledetail__feed-container"}>
                                                                        <div
                                                                            className={"titledetail__feed-title mb-3"}>FYSEE
                                                                            TV
                                                                        </div>
                                                                        <div className={"d-flex flex-wrap flex-row"}>
                                                                            {fyseeVideoList.map((o, i) => {
                                                                                return (
                                                                                    <a href={'/' + categorySlug + '/' + titleSlug + '/videos/' + o.slug}
                                                                                       key={i}
                                                                                       className={"d-block titledetail__feed-item mb-3 pe-3 text-white 'col-12 col-md-6"}>
                                                                                        <div
                                                                                            className="d-flex align-items-start justify-content-start">
                                                                                            <div
                                                                                                className="col-3 d-flex flex-column align-items-start justify-content-center">
                                                                                                <img
                                                                                                    src={s3Url + o.thumbnail}
                                                                                                    alt="NF" style={{
                                                                                                    pointerEvents: 'none',
                                                                                                    backgroundColor: 'rgba(255,255,255, 0.05)'
                                                                                                }}/>
                                                                                            </div>
                                                                                            <div className="ps-3 col-9">
                                                                                                <div
                                                                                                    className="d-block  mb-2"
                                                                                                    style={{
                                                                                                        lineHeight: '1.3em',
                                                                                                        fontSize: '.9rem'
                                                                                                    }}>{o.title}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                : null
                                                        }

                                                        {
                                                            hasMediaRead && scripts ?

                                                                <div className={"col-12 mb-4 "}>
                                                                    <div className={"titledetail__feed-container"}>
                                                                        <div
                                                                            className={"titledetail__feed-title mb-3"}>READ
                                                                        </div>
                                                                        <div className={"d-flex flex-wrap flex-row"}>
                                                                            {scripts.map((o, i) => {
                                                                                return (
                                                                                    <a href={'/' + categorySlug + '/' + titleSlug + '/read/' + o.slug}
                                                                                       key={i}
                                                                                       className={"d-block titledetail__feed-item mb-3 pe-3 text-white col-12 col-md-6"}>
                                                                                        <div
                                                                                            className="d-flex align-items-start justify-content-start">
                                                                                            <div
                                                                                                className="col-3 d-flex flex-column align-items-start justify-content-center p-2"
                                                                                                style={{backgroundColor: 'rgba(255,255,255, 0.05)'}}>
                                                                                                <img
                                                                                                    src={scriptPlaceholder}
                                                                                                    alt="NF"
                                                                                                    className="mx-auto"
                                                                                                    style={{
                                                                                                        maxWidth: 106,
                                                                                                        maxHeight: 60,
                                                                                                        pointerEvents: 'none'
                                                                                                    }}/>
                                                                                            </div>
                                                                                            <div className="ps-3 col-9">
                                                                                                <div
                                                                                                    className="d-block  mb-2"
                                                                                                    style={{
                                                                                                        lineHeight: '1.3em',
                                                                                                        fontSize: '.9rem'
                                                                                                    }}>{o.title.replace('.pdf', '').replace('.PDF', '')}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                : null
                                                        }


                                                    </div>
                                                </div>
                                                : null
                                        }

                                        {
                                            hasAwards && competitions ?
                                                <div className="d-flex d-md-none my-5 pb-5 flex-md-fill flex-column">
                                                    <CompetitionsAwards show_id={titleId} slug={titleSlug}
                                                                        titleName={titleName} award_count={award_count}
                                                                        competitions={competitions}/>
                                                </div>
                                                : ""
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                        {
                            titleBGImage !== '' ?
                                <div
                                    className={"main__background titledetail__background " + ((scrolled || trailerLoaded || mediaSection) ? ("titledetail__background--blurred") : (""))}
                                    style={{
                                        backgroundImage: 'url(' + titleBGImage + ')'
                                    }}/>
                                : null
                        }
                    </>
                }/>

                <Route exact path={'/awards'} element={

                    <>

                        <div className="subnav__container--absolute d-flex align-items-center">
                            <ReturnToShow categorySlug={categorySlug}
                                          titleSlug={titleSlug}
                                          titleName={titleName}/>
                        </div>

                        <div className="content__container d-flex flex-md-fill ">
                            <div className="width_ctrl row no-gutters flex-md-fill">

                                <div className="col-12 col-md-4 sidebar__container--titlepage ">
                                    <div
                                        className="sidebar__container--inner d-flex flex-column justify-content-center">
                                        <div className="titlepage__logo--container px-0 px-md-3 ">

                                        {(titleTTImage !== "")?(
                                          <a href={'/'+categorySlug+'/'+titleSlug} className="titletreatment-container d-flex justify-content-center align-items-center mb-0"><img src={titleTTImage} style={{maxHeight:'150px'}} className="titletreatment my-3" alt={titleName} /></a>
                                        ):(
                                          ""
                                        )}

                                            <div className="d-flex titlepage__button--container pb-3 justify-content-center align-items-center">
                                                <a href={'/'+categorySlug+'/'+titleSlug}><button className="button__box button__outline"><svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> Back</button></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {
                                    hasAwards && competitions ?

                                        <div className="main__content pt-5 pt-md-0 col-12 col-md-8 ps-0 ps-md-5 pe-0 d-flex flex-column align-items-center justify-content-start justify-content-md-center">

                                            <div className="scroll-container" style={{
                                            maxHeight:'75vh',
                                            overflowY:'scroll',
                                            width:'100%'
                                            }}>

                                                <CompetitionsAwardsColumns show_id={titleId} slug={titleSlug} titleName={titleName} award_count={award_count} competitions={competitions} />

                                            </div>

                                        </div>

                                    : null
                                }



                            </div>
                        </div>
                        {
                            titleBGImage !== '' ?
                                <div className={"main__background titledetail__background titledetail__background--blurred"} style={{
                                    backgroundImage: 'url('+titleBGImage+')'
                                }} />
                            : null
                        }
                    </>

                } />

                <Route path={'/:mediaSection'} element={

                    <>

                        <div className="subnav__container--absolute d-flex align-items-center">
                            <ReturnToShow categorySlug={categorySlug}
                                          titleSlug={titleSlug}
                                          titleName={titleName} />
                        </div>

                        <div className="content__container d-flex flex-md-fill ">
                            <div className="width_ctrl row no-gutters flex-md-fill">

                                <Sidebar award_count={ award_count }
                                         titleName={titleName}
                                          titleId={titleId}
                                          titleSlug={titleSlug}
                                          titleTTImage={titleTTImage}
                                          trailerId={trailerId}
                                          trailerLoaded={trailerLoaded}
                                          setTrailerLoaded={setTrailerLoaded}
                                          stopVideo={stopVideo}
                    stopMP4Video={stopMP4Video}
                                          playVideo={playVideo}
                                          socialFB={socialFB}
                                          socialTW={socialTW}
                                          socialIG={socialIG}
                                          sagIneligible={sagIneligible}
                                          reviewQuote={reviewQuote}
                                          reviewCredit={reviewCredit}
                                          viewingLink={viewingLink}
                                          availableOn={availableOn}
                                          hasAwards={hasAwards}
                                          competitions={competitions}
                                          hasImages={hasImages}
                                          hasAudio={hasAudio}
                                          hasEvents={hasEvents}
                                          hasMediaRead={hasMediaRead}
                                          hasVideos={hasVideos}
                                          hasLinks={hasLinks} />

                                <div className="main__content pt-5 pt-md-0 col-12 col-md-8 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
                                    <div className="w-100 scroll-container" style={{maxHeight:'85vh',overflowY:'scroll'}}>

                                        <MediaSectionComponent taglist_videos={taglist_videos}
                                                        fyseeVideoList={fyseeVideoList}
                                                        taglist_images={taglist_images}
                                                        taglist_presentations={taglist_presentations}
                                                        taglist_mp3single={taglist_mp3single}
                                                        taglist_mp3album={taglist_mp3album}
                                                        taglist_spotify={taglist_spotify}
                                                        scripts={scripts}
                                                        links={links}
                                                        secondaryLinks={secondaryLinks}
                                                        eventList={eventList} />

                                        {/*
                                            hasAwards ?
                                                <div className="d-flex d-md-none my-5 pb-5 flex-md-fill flex-column">
                                                <CompetitionsAwards show_id={titleId} slug={titleSlug} titleName={titleName}  award_count={award_count} />
                                                </div>
                                            :""
                                        */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            titleBGImage !== '' ?
                                <div className={"main__background titledetail__background titledetail__background--blurred"} style={{
                                    backgroundImage: 'url('+titleBGImage+')'
                                }} />
                            : null
                        }

                    </>

                } />

                <Route path={'/:mediaSection/:mediaSlug'} element={

                    <>

                        <div className="subnav__container--absolute d-flex align-items-center">
                            <ReturnToMedia categorySlug={categorySlug}
                                          titleSlug={titleSlug}
                                          titleName={titleName} />
                        </div>

                        <div className="content__container d-flex flex-md-fill ">
                            <div className="width_ctrl row no-gutters flex-md-fill">

                                <Sidebar award_count={award_count}
                                   titleName={titleName}
                                    titleId={titleId}
                                    titleSlug={titleSlug}
                                    titleTTImage={titleTTImage}
                                    trailerId={trailerId}
                                    trailerLoaded={trailerLoaded}
                                    setTrailerLoaded={setTrailerLoaded}
                                    stopVideo={stopVideo}
                    stopMP4Video={stopMP4Video}
                                    playVideo={playVideo}
                                    socialFB={socialFB}
                                    socialTW={socialTW}
                                    socialIG={socialIG}
                                    sagIneligible={sagIneligible}
                                    reviewQuote={reviewQuote}
                                    reviewCredit={reviewCredit}
                                    viewingLink={viewingLink}
                                    availableOn={availableOn}
                                    hasAwards={hasAwards}
                                    competitions={competitions}
                                    hasImages={hasImages}
                                    hasAudio={hasAudio}
                                    hasEvents={hasEvents}
                                    hasMediaRead={hasMediaRead}
                                    hasVideos={hasVideos}
                                    hasLinks={hasLinks} />

                                <div className="main__content pt-5 pt-md-0 col-12 col-md-8 px-2 px-md-0 ps-md-5 d-flex flex-column align-items-center justify-content-start justify-content-md-center">

                                    <div className="d-block w-100 flex-fill">
                                        {<TitleMediaSingle/>}
                                    </div>

                                    {/*
                                        hasAwards ?
                                            <div className="d-flex d-md-none my-5 pb-5 flex-md-fill flex-column">
                                              <CompetitionsAwards show_id={titleId} slug={titleSlug} titleName={titleName}  award_count={award_count}  />
                                            </div>
                                        :""
                                    */}

                                </div>

                            </div>
                        </div>
                        {
                            titleBGImage !== '' ?
                                <div className={"main__background titledetail__background titledetail__background--blurred"} style={{
                                    backgroundImage: 'url('+titleBGImage+')'
                                }} />
                            : null
                        }

                    </>

                }/>

            </Routes>




            <div className="titledetail__background--gradient" />
            <div className="d-none d-md-block titledetail__sidebar--background-container" >
              <div className="width_ctrl " >
                <div className="titledetail__sidebar--background col-4" />
              </div>
            </div>
            <div className="main__gradient"/>


        </div>


    )
}

export default TitleDetail
