import React, {useEffect, useState} from 'react'
import EventRSVPForm from "./EventRSVPForm"

import Moment from 'react-moment'
import 'moment-timezone'


import {getEventBySlug} from "../../utils/api/helpers/event";

import spacerSquare from '../../Common/_assets/img/placeholder/spacer_portrait_film.png'
import shadowImage from '../../Common/_assets/img/bg/events-thumbmail-shadow.png'
import fyseePlaceholder from '../../Common/_assets/img/placeholder/Netflix_FYSEE_placeholder.jpg'
import fyseeSpacer from '../../Common/_assets/img/placeholder/emy_miniposter_placeholder.png'
import {useParams} from "react-router-dom";

const EventDetail = () => {

    const { eventSlug } = useParams();

    const [startDate, setStartDate] = useState(null),
        [startDateNum, setStartDateNum] = useState(null),
        [startTime, setStartTime] = useState(null),
        [eventTitle, setEventTitle] = useState(''),
        [eventDescription, setEventDescription] = useState(''),
        [eventCategories, setEventCategories] = useState([]),
        [eventShows, setEventShows] = useState([]),
        [allowGuests, setAllowGuests] = useState(false),
        [emailDisabled, setEmailDisabled] = useState(false),
        [eventMediaSrc, setEventMediaSrc] = useState(null),
        [venueTimezone, setVenueTimezone] = useState(null),
        [venueName, setVenueName] = useState(null),
        [venueAddress, setVenueAddress] = useState(null),
        [venueCity, setVenueCity] = useState(null),
        [ guestThreshold, setGuestThreshold ] = useState( 0 )

    const s3Url = 'https://jwlfyc-public-us-east-1.s3.amazonaws.com/'

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            Moment.globalTimezone = 'America/Los_Angeles'
            Moment.globalLocale = 'en-us'
            // console.log("event slug", eventSlug)
            getEventBySlug(eventSlug)
                .then(result => {
                    // console.log("event", result.data)
                    if (result.data) {
                        setEventTitle(result.data.title)
                        setEventDescription(result.data.description)
                        setAllowGuests(result.data.allow_guests > 0)
                        setEmailDisabled(result.data.email_disabled)
                        setEventCategories([])
                        setGuestThreshold(result.data.guest_threshold)
                        setVenueName(result.data.venue_name)
                        setVenueAddress(result.data.venue_address)
                        setVenueCity(result.data.venue_city)
                        setVenueTimezone(result.data.venue_timezone)
                        setEventShows(result.data.shows)

                        if (Object.keys(result.data.shows).length===1) {
                            if (result.data.slug==='1722262992' || result.data.slug==='1722625525') {
                                setEventMediaSrc('https://jwlfyc-public-us-east-1.s3.amazonaws.com/1722469670493-emy_miniposter_samurai.jpg')
                            } else {
                                setEventMediaSrc(s3Url+result.data.filename)
                            }

                        } else {
                            setEventMediaSrc(fyseePlaceholder)
                        }

                        const tempDateTime = result.data.start_date.split('T')[0]+'T12:00:00.000'
                        setStartDate(tempDateTime)
                        setStartDateNum(result.data.start_date.split('T')[0].split('-')[2])
                        let hours = result.data.start_time.split(':')[0]+':'+result.data.start_time.split(':')[1]
                        let hoursFormatted = tConvert(hours)
                        setStartTime(hoursFormatted)
                    }
                })
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSlug])

    const tConvert = (time) => {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
          time = time.slice(1); // Remove full string match value
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
  }

    return (
        <div className="width_ctrl ">
        <div className="pt-5 events__grid--inner row justify-content-start event__excerpt--container  ">

            <div className="col-12 col-md-4 ">
                <div className="event__excerpt--inner">
                    <div className="event__excerpt--top d-flex flex-column " style={{
                      backgroundImage:"url("+eventMediaSrc+")",
                      backgroundSize:"cover",
                      backgroundPosition:"center",
                      backgroundRepeat:"no-repeat",
                      backgroundColor:"rgba(0,0,0,.8)"
                    }}>
                        <div className="event__excerpt--top-inner d-flex p-3 h-100">
                            {startDate ?
                                <div className="d-flex flex-column ms-auto">
                                    <div className="event__excerpt--date-dayname text-center"><Moment format="dddd">{startDate}</Moment></div>
                                    <div className="event__excerpt--date-day text-center">{startDateNum}</div>
                                    <div className="event__excerpt--date-month text-center"><Moment format="MMM">{startDate}</Moment></div>
                                </div>
                                : ""}

                        </div>
                        <div className="event__excerpt--top-shadow">
                            <img src={shadowImage} className=" eventthumbnail__spacer" alt="Netflix" />
                        </div>

                        {
                            (eventSlug === '1722262992' || eventSlug === '1722625525') ?
                                <img src={fyseeSpacer} className=" eventthumbnail__spacer" alt="Netflix"/>
                                :
                                <img src={spacerSquare} className=" eventthumbnail__spacer" alt="Netflix"/>

                        }


                    </div>

                </div>

            </div>

            <div className="col-12 col-md-8 ">

                <div className="event__excerpt--inner">

                    <div className="event__excerpt--bottom event__excerpt--detail-container d-flex flex-column p-4">

                        <div className="event__excerpt--film pb-4 d-flex justify-content-start">
                            <a className="subnav__container--return d-flex align-items-center"
                            href={ '/events' }
                            style={{textTransform:'capitalize',color:"#f5f5f5"}}>
                              <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-0 me-md-2"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                              <span style={{color:"#f5f5f5"}}>All Events</span>
                            </a>
                        </div>

                        <div className="event__excerpt--title event__excerpt--title-large ">{eventTitle}</div>

                        <div className="event__excerpt--film pt-2">
                            {
                                eventShows.map( (f, i) => <span key={i} className="event__excerpt--show">{i>0 ? ', ' : ''}<a href={'/'+f.genres[0].slug+'/'+f.slug}>{f.title}</a></span> )
                            }
                        </div>

                        <div className=" mt-4">

                          {startDate ?
                              <div className="p-2" style={{backgroundColor:'rgba(255, 255, 255, 0.1)'}}>
                                  <div className="mb-1 font-weight-bold"  style={{fontWeight:'bold'}}><span><svg viewBox="0 0 24 24" width="12" height="12" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-2"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg></span><Moment format="dddd MMMM D, YYYY">{startDate}</Moment></div>
                                  <div className=""><span><svg viewBox="0 0 24 24" width="12" height="12" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-2"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg></span>{startTime}&nbsp;
                                      {venueTimezone}</div>
                              </div>
                              :""}

                            <div className="mb-4">
                                <div className="p-2 event__excerpt--venue mb-3">
                                    <div className="font-weight-bold mb-1" style={{fontWeight: '600'}}><span><svg
                                        viewBox="0 0 24 24" width="12" height="12" stroke="currentColor"
                                        strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                        className="me-2"><rect x="3" y="4" width="18" height="18" rx="2"
                                                               ry="2"></rect><line x1="16" y1="2" x2="16"
                                                                                   y2="6"></line><line x1="8" y1="2"
                                                                                                       x2="8"
                                                                                                       y2="6"></line><line
                                        x1="3" y1="10" x2="21" y2="10"></line></svg></span>{venueName}</div>
                                    <div className="">{venueAddress}, {venueCity}</div>
                                </div>
                            </div>

                        </div>


                        <div className="d-none event__excerpt--tags-container mt-3">
                            {
                                eventCategories.map((c, i) => <span key={i} className="event__excerpt--tag">{c}</span>)
                            }
                        </div>


                        <div className="event__excerpt--description mt-3 mb-3"
                             dangerouslySetInnerHTML={{__html: eventDescription}}/>

                        <div className="event__excerpt--description mt-3 mb-0">

                            {allowGuests && emailDisabled ? '' : ''}
                            {
                              (emailDisabled === 1) ?
                                <p className="text-center font-weight-bold my-5 text-red">RSVPs to this event are closed.</p>
                              : (emailDisabled === 0) ?
                                <>
                                    <h1 className="events__rsvp--header col-12 text-center" >RSVP</h1>
                                    <EventRSVPForm event_slug={ eventSlug }
                                                  title={eventTitle}
                                                  start_date={startDate}
                                                  start_time={startTime}
                                                   allowGuests={allowGuests}
                                                   guestThreshold={guestThreshold}
                                        />
                                </>
                              : null
                            }

                        </div>

                    </div>
                </div>

            </div>


        </div>
        <div className="main__background" />
        </div>

    )
}

export default EventDetail
