import React, {useLayoutEffect, useState, useRef} from 'react'
import TitleMediaMp3PlaylistItem from './TitleMediaMp3PlaylistItem'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const TitleMediaMp3Playlist = ({album}) => {

    const [currentTrack, setCurrentTrack] = useState()

    const audioPlayerRef = useRef()

    const setPlaylistTrack = (filePath) =>{
      let s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/"
      let fullPath = s3Url+filePath
      setCurrentTrack(fullPath)
      audioPlayerRef.current.audio.current.play()
    }


    useLayoutEffect( () => {
        let subscribed=true
        let s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/"
        if (subscribed) {
          setPlaylistTrack(s3Url+album[0].filename)
        }

        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ album ])


    return (


      <>

        <div className={"titledetail__asset--section  titledetail__audio--container mb-4 "}>

              <div className="d-flex flex-wrap no-gutters align-items-center text-left">
                <div className="col-12 mb-4">
                  <AudioPlayer
                    ref={audioPlayerRef}
                    src={currentTrack}
                  />
                </div>
                <div className="col-12 text-left font-bold">
                  {(album)?(
                      album.map( (obj, i) => {
                          return (
                            <TitleMediaMp3PlaylistItem
                              key={i}
                              // currentTrackIndex={i}
                              // setCurrentTrackIndex={setCurrentTrackIndex}
                              setCurrentTrack={setCurrentTrack}
                              currentTrack={currentTrack}
                              setPlaylistTrack={setPlaylistTrack}
                              assetObject={obj} />
                          )
                      })
                    ):("")
                  }
                </div>
              </div>

        </div>

      </>

    )
}

export default TitleMediaMp3Playlist
