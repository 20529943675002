import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import TitleMediaVideo from '../Video/TitleMediaVideo'
import TitleMediaGrid from '../TitleMediaGrid'
import TitleMediaList from '../TitleMediaList'
import TitleMediaAudio from '../Audio/TitleMediaAudio'
import EventExcerptTitlePage from '../../../Event/EventExcerptTitlePage'

const MediaSectionComponent = ({taglist_videos,
                        fyseeVideoList,
                        taglist_images,
                        taglist_presentations,
                        taglist_mp3single,
                        taglist_mp3album,
                        taglist_spotify,
                        scripts,
                        links,
                        secondaryLinks,
                        eventList }) => {


    const match = useParams()
    const {categorySlug,titleSlug} = useParams()
    const [mediaSection, setMediaSection] = useState(null)

    useEffect( () => {
        let subscribed=true
        if (subscribed && match) {
            setMediaSection(match.mediaSection)
          }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ match.mediaSection ])

    return (
        <>
            { mediaSection ?
                <>
                {mediaSection==='videos' ?

                    <TitleMediaVideo mediaSection={mediaSection} taglist_videos={taglist_videos} fyseeVideoList={fyseeVideoList} />

                : mediaSection === 'read' ?

                    <div className="pe-0 pe-md-5 mb-5 position-relative w-100 scroll-container" style={{maxHeight:'75vh',height:'75vh',overflow:'scroll'}}>
                    <TitleMediaGrid mediaSection={mediaSection} titleSlug={titleSlug} categorySlug={categorySlug} mediaList={scripts} />
                    </div>

                : mediaSection === 'presentations' ?

                    <div className="pe-0 pe-md-5 mb-5 position-relative w-100 scroll-container" style={{maxHeight:'75vh',height:'75vh',overflow:'scroll'}}>
                    <TitleMediaGrid mediaSection={mediaSection} titleSlug={titleSlug} categorySlug={categorySlug} mediaList={taglist_presentations} />
                    </div>

                : mediaSection === 'images' ?

                    <div className="pe-0 pe-md-5 mb-5 position-relative w-100 scroll-container" style={{maxHeight:'75vh',height:'75vh',overflow:'scroll'}}>
                    <TitleMediaGrid mediaSection={mediaSection} titleSlug={titleSlug} categorySlug={categorySlug} mediaList={taglist_images} />
                    </div>

                : mediaSection === 'audio' ?

                    <TitleMediaAudio mediaSection={mediaSection} taglist_spotify={taglist_spotify} taglist_mp3single={taglist_mp3single} taglist_mp3album={taglist_mp3album} />

                : mediaSection === 'events' ?

                    <div className="home__event--container pe-0 pe-md-5 mb-5 position-relative d-flex flex-column align-items-center">
                        <h2 className="text-uppercase text-center my-5" style={{fontWeight:300,lineHeight:'1.2em',letterSpacing:'.4rem'}}>EVENTS</h2>
                        {eventList ?
                            eventList.map((o,i)=>{
                                return(
                                    <EventExcerptTitlePage event_object={o} />
                                )
                            })
                        :null}
                    </div>

                : mediaSection === 'press' ?
                    <div className="pe-0 pe-md-5 mb-5 position-relative w-100">
                    <h2 className="text-uppercase text-center my-5 text-muted" style={{fontWeight:600,lineHeight:'1.2em',letterSpacing:'.4rem'}}>PRESS</h2>
                    {
                        links ?
                            <TitleMediaGrid mediaSection={mediaSection} titleSlug={titleSlug} categorySlug={categorySlug} mediaList={links} />
                        : null
                    }
                    {
                        secondaryLinks ?
                            <TitleMediaList mediaSection={mediaSection} titleSlug={titleSlug} categorySlug={categorySlug} mediaList={secondaryLinks} />
                        : null
                    }

                    </div>

                : null}
                </>
            : null
            }
        </>
    )
}

export default MediaSectionComponent
