import React from 'react'
import fyseeTVLogo from '../../../Common/_assets/img/logo/logo-fysee-horizontal.png'
import VisibleCategories from '../../../utils/visibleCategories'
import SidebarCategory from './SidebarCategory'

const Desktop = ({categories, videoPlaying, playVideo, pauseVideo}) => {

    return (

        <div className="sidebar__container--inner d-flex flex-column ">

          <ul className="sidebar__container--list flex-fill d-flex flex-column justify-content-between">

              {
                categories ?
                  categories.map((c,i)=>{
                      if (VisibleCategories.includes(c.slug)) {
                          return <SidebarCategory key={i} category={c} />
                      } else {
                          return null
                      }
                  })
                :""
              }

              <li className="">
                <div className="homepage__cta--container d-flex flex-column flex-wrap mt-5">
                  <a href="https://fyseetv.netflixawards.com/" className="text-white" target="_blank" rel="noopener noreferrer">
                    <div className="homepage__cta mb-5">
                      <div className="homepage__cta--item text-left ">
                        <div className="font-weight-bold homepage__cta--item-title mb-2"><img src={fyseeTVLogo} alt="FYSEE" style={{maxWidth:'160px'}}/></div>
                        <div className="mt-1 homepage__cta--item-desc" style={{maxWidth:'100%'}}>Explore the shows you love.</div>
                      </div>
                    </div>
                  </a>

                </div>
              </li>

          </ul>

        </div>

    )
}

export default Desktop
