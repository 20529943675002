import React, { useEffect, useState } from 'react'
import {useParams} from "react-router-dom";
import {Row, Col, Form, Button, Modal } from 'react-bootstrap'
import {ReviseGuestForm} from "../Event/ReviseGuestForm";
import fyseePlaceholder from '../../Common/_assets/img/placeholder/Netflix_FYSEE_placeholder.jpg'
import Moment from 'react-moment'
import 'moment-timezone'

import {fetchRsvp} from "../../utils/api/helpers/rsvp";
import {cancelRsvp} from "../../utils/api/helpers/rsvp";
import {reviseRsvp} from "../../utils/api/helpers/rsvp";

const UpdateRSVP = () => {

    const { hash } = useParams();

    const [message,setMessage] = useState( null )

    // const s3Url = 'https://jwlfyc-public-us-east-1.s3.amazonaws.com/'

    const [ formSubmitted, setFormSubmitted ] = useState( false ),
        [ formIsValid, setFormIsValid ] = useState( false ),
        [ rsvp_status, setRsvpStatus ] = useState( null ),
        [ showModal, setShowModal ] = useState( false ),
        [ logoImageSrc, setLogoImageSrc ] = useState( null ),
        [ allowGuests, setAllowGuests ] = useState( false ),
        [ encEmail, setEncEmail ] = useState( null ),
        [ name, setName ] = useState( "" ),
        [ guild_affiliation_id, setGuildAffiliationId ] = useState( null ),
        [ guild_affiliation_name, setGuildAffiliationName ] = useState( null ),
        [ guestList, setGuestList ] = useState( [] ),
        [ guestThreshold, setGuestThreshold ] = useState( 0 ),
        [ cancelled, setCancelled ] = useState( null ),
        // [ confirmed, setConfirmed ] = useState( null ),
        // [ requested, setRequested ] = useState( null ),
        [ emailDisabled, setEmailDisabled ] = useState( null ),
        [startDate, setStartDate] = useState(null),
        [startTime, setStartTime] = useState(null),
        [timeZone, setTimeZone] = useState(null),
        [eventTitle, setEventTitle] = useState(''),
        [eventDescription, setEventDescription] = useState(''),
        [venueName, setVenueName] = useState([]),
        [venueAddress, setVenueAddress] = useState([]),
        // [venueId, setVenueId] = useState([]),
        [eventShows, setEventShows] = useState([])

    const person = () => {
        return {
            name: name,
            emailBytes:encEmail,
            ref_hash: hash,
            guild_id:guild_affiliation_id,
            guests: guestList
        }
    }


    useEffect( () => {
        let subscribed = true
        if (subscribed) {

            window.scrollTo({left: 0,top: 0,behavior: "auto"})
            setFormIsValid(false)
            setMessage(null)
            setFormSubmitted(false)

            fetchRsvp(hash).then((result) => {
                console.log('fetchrsvp',result)
                if (result.data) {
                    setEncEmail(result.data.rsvp.emailBytes.data)
                    setName(result.data.rsvp.name)
                    setRsvpStatus('CONFIRMED')
                    setLogoImageSrc(fyseePlaceholder)
                    setAllowGuests(result.data.rsvp.allow_guests)
                    setGuestList(result.data.guests)
                    setGuestThreshold(result.data.rsvp.guest_threshold)
                    setGuildAffiliationName(result.data.rsvp.guildName)
                    setGuildAffiliationId(result.data.rsvp.guildId)
                    setCancelled(result.data.rsvp.cancelled)
                    // setConfirmed(result.data.rsvp.confirmed)
                    // setRequested((!result.data.rsvp.cancelled && !result.data.rsvp.confirmed))
                    setEmailDisabled(result.data.rsvp.email_disabled)
                    let tempDateTime = result.data.rsvp.start_date.split('T')[0]+'T12:00:00.000'
                    setStartDate(tempDateTime)
                    let hours = result.data.rsvp.start_time.split(':')[0]+':'+result.data.rsvp.start_time.split(':')[1]
                    let hoursFormatted = tConvert(hours)
                    setStartTime(hoursFormatted)
                    setTimeZone(result.data.rsvp.timezone)
                    setEventTitle(result.data.rsvp.event_title)
                    setEventDescription(result.data.rsvp.event_description)
                    setVenueName(result.data.rsvp.venueName)
                    setVenueAddress(result.data.rsvp.venueAddress)
                    // setVenueId(result.data.rsvp.venue_id)
                    setEventShows(result.data.shows)
                }
            })
        }
        return ()=>{subscribed = false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash])

    const tConvert = (time) => {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }


    const updateRsvp = e => {
        e.preventDefault()
        console.log('revise',person(), hash)
        reviseRsvp(hash, person(), result => {
            setFormSubmitted(true)
            setRsvpStatus(null)
        })
    }
    const cancel = () => {
        setCancelled(true)
        cancelRsvp(hash)
    }

    const updateGuestList = (idx, guest, remove) => {
        let l = guestList.slice()
        if (remove) {
          l = l.filter((el)=>{
            return el.name!==guest.name
          })
          setGuestList(l)
        } else {
          if(idx < 0) {
              l.push(guest)
          } else {
              l[idx] = guest
          }
          setGuestList(l)
        }
    }

    return (
        <>

            <div className="page__container mt-4 mb-5">

                <div className="width_ctrl">

                <>

                      <div data-rsvp-status={rsvp_status} className="d-flex flex-wrap flex-md-nowrap">

                          <div className="col-12 col-md-3 my-5  p-3" style={{background:'rgba(255, 255, 255, 0.1)'}}>

                              {
                                logoImageSrc ?
                                  <div className="mb-3">
                                    <img src={logoImageSrc} alt="Netflix" className=""/>
                                  </div>
                                :null
                              }

                              <div className="event__excerpt--film event__excerpt--film-single mb-3">
                                  {
                                      eventShows.map( (f, i) => <span key={i} className="event__excerpt--film">{i>0 ? ', ' : ''}{f.title}</span> )
                                  }
                              </div>

                              <div className="event__excerpt--title ">{eventTitle}</div>

                              <div className=" mt-3">

                                {startDate ?
                                    <div className="mb-3">

                                        <div className="mb-1 font-weight-bold">
                                            <Moment format="dddd MMMM D, YYYY">{startDate}</Moment>
                                        </div>
                                        <div className="">{startTime}&nbsp;{timeZone}</div>
                                    </div>
                                    :""}

                                  <div className=" mb-1">
                                      <div className="event__excerpt--venue mb-3">
                                          <div className="font-weight-bold mb-1">{venueName}</div>
                                          <div className="">{venueAddress}</div>
                                      </div>
                                  </div>

                                  <div className="event__excerpt--description mt-3 mb-3"
                                       dangerouslySetInnerHTML={{__html: eventDescription}}/>

                              </div>

                          </div>

                          {
                            !cancelled ?


                              <div className="col-12 col-md-9 ps-0 ps-md-5">
                                {
                                    emailDisabled === 0 ?
                                        <>
                                                    {
                                                      !message ?

                                                      <div className="d-block">

                                                          <div className="my-5 font-weight-bold  text-center" style={{fontSize:'3rem'}}>Update RSVP</div>

                                                          <div className="text-center my-5 w-75 mx-auto" style={{lineHeight:'1.2'}}>If you wish to update your RSVP, you may change your name, affiliation, and/or guest(s) by editing the form below.</div>

                                                          {
                                                              !formSubmitted ?
                                                                  <Form>

                                                                      <Row>
                                                                          <Col lg={ 12 }>
                                                                              <Form.Group controlId='signup_name'>
                                                                                  <Form.Control value={ name }
                                                                                                required
                                                                                                style={{ opacity:1, borderBottom: "1px solid #666" }}
                                                                                                onChange={ (e) => {
                                                                                                    setName(e.target.value)
                                                                                                }}
                                                                                                placeholder='Name' />

                                                                              </Form.Group>
                                                                              {(!formIsValid && name==='')?(
                                                                                  <div className="validation__message">Please enter a Name</div>
                                                                              ):("")}

                                                                          </Col>
                                                                      </Row>
                                                                      <Row>
                                                                          <Col lg={ 12 }>
                                                                              <div className={'my-3 pe-2'} style={{opacity:'.5'}}>
                                                                                  Guild Affiliation: {guild_affiliation_name}
                                                                              </div>
                                                                          </Col>

                                                                          {
                                                                              allowGuests && guestThreshold > 0 ?
                                                                                  (
                                                                                      <div className="p-3 w-100">
                                                                                          <div className="row">
                                                                                              <div className="col-12 mb-3 align-items-center">
                                                                                                  <div className="events__rsvp--header mb-2" style={{fontSize:"1rem"}}>GUESTS</div>

                                                                                                  {
                                                                                                    guestList.length < guestThreshold ?
                                                                                                      <Button className="ms-auto button__outline button__box"
                                                                                                              onClick={ (e) => {
                                                                                                                updateGuestList(-1, { name: "" })
                                                                                                            } }>
                                                                                                          <span className="label-small">+ Add Guest Row</span>
                                                                                                      </Button>
                                                                                                    :
                                                                                                      <div className="mx-auto text-center button__outline button__box">
                                                                                                        Up to {guestThreshold} guests are allowed for this event.
                                                                                                      </div>
                                                                                                  }


                                                                                              </div>
                                                                                          </div>
                                                                                          {guestList ?
                                                                                              guestList.map((gl, i) =>
                                                                                                  <ReviseGuestForm key={ i } idx={ i } guest={gl} updateGuest={ updateGuestList } formIsValid={formIsValid} />
                                                                                              ) : ""
                                                                                          }
                                                                                      </div>
                                                                                  )
                                                                                  :  ""
                                                                          }

                                                                          <Col lg={ 12 } className="my-4 d-flex justify-content-center">
                                                                              <Button className="button__box w-100"
                                                                                      onClick={ (e) =>{
                                                                                          const gl=guestList.filter(o=>{
                                                                                              return o.name !==''
                                                                                          })
                                                                                          setGuestList(gl)
                                                                                          setShowModal(true)
                                                                                      } }>
                                                                                  Update my RSVP
                                                                              </Button>
                                                                          </Col>
                                                                      </Row>
                                                                  </Form>

                                                                  :

                                                                  <>
                                                                      <div className="text-center my-5 font-weight-bold">Thank you for submitting your RSVP updates! <br/><br/>You will receive an email shortly.</div>
                                                                  </>

                                                          }
                                                      </div>

                                                      : null
                                                    }

                                        </>
                                    : emailDisabled === 1 ?

                                        <>
                                          <div className="my-5 font-weight-bold  text-center" style={{fontSize:'3rem'}}>RSVPs Closed</div>
                                          <div className="text-center my-5">Changes to RSVPs for this event are closed.</div>
                                        </>

                                    : null
                                  }


                                  <div className="my-5 font-weight-bold  text-center" style={{fontSize:'3rem'}}>Cancel RSVP</div>

                                  {
                                    message ?
                                      <div className="text-center my-5 w-50 mx-auto" style={{lineHeight:'1.2'}}>{message}</div>
                                    :
                                      <>
                                        <div className="text-center my-5 w-75 mx-auto" style={{lineHeight:'1.2'}}>If you wish to cancel your RSVP, click the button below. You will receive a cancellation email shortly.</div>

                                        <Col lg={ 12 } className="my-4 d-flex justify-content-center">
                                            <Button className="button__box w-100"
                                                    onClick={ (e) =>{
                                                        cancel()
                                                    } }>
                                                Cancel my RSVP and remove me from the list
                                            </Button>
                                        </Col>
                                      </>

                                  }

                              </div>

                          :

                            <div className="col-12 col-md-9 ps-0 ps-md-5">
                              <div className="my-5 font-weight-bold  text-center" style={{fontSize:'3rem'}}>RSVP Cancelled</div>
                              <div className="text-center my-5">Your RSVP has been cancelled.</div>
                            </div>

                        }

                      </div>
                </>





                </div>
            </div>
            <div className="main__background" />


            <Modal show={ showModal } onHide={()=>{setShowModal(false)}} size="lg" dialogClassName="event__modal-new eventmodal__container" centered={true} style={{backgroundColor:'#000'}}>
                <Modal.Header closeButton>
                    <Modal.Title>Revisions</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="my-3 text-center">
                      Please review your revision before submitting.
                    </div>
                    <div className="w-50 h-50 mx-auto my-5 p-5" style={{background: 'rgba(255,255,255,.1)'}}>
                        <div className="font-weight-bold mb-1 ">Name:</div>
                        <div className="pb-2">{name}</div>
                        <div className="font-weight-bold mb-1 ">Guild Affiliation:</div>
                        <div className="pb-2">{guild_affiliation_name}</div>
                        <div>
                            {(Object.keys(guestList).length && guestList[0].name) ?
                                <>
                                    <div className="font-weight-bold mb-1 ">Guests:</div>
                                    {guestList.map((gl, i) =>
                                        <div key={i} className="mb-1">{gl.name}</div>
                                    )}
                                </>
                                :
                                <>
                                    <div className="font-weight-bold mb-1">Guests:</div>
                                    <div className="mb-1">No Guests Selected</div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="text-center mb-2">
                      <Button className="button__box px-4"
                              onClick={ (e) =>{
                                  setShowModal(false)
                                  updateRsvp(e)
                              } }>
                          Yes, submit these updates to my RSVP
                      </Button>
                    </div>
                    <div className="text-center mb-5">
                      <Button className="button__box button__grey px-4"
                              onClick={ (e) =>{
                                  setShowModal(false)
                              } }>
                          No, let me make further changes
                      </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )

}

export default UpdateRSVP
