const VisibleCategories = [
    'drama-series',
    'comedy-series',
    'variety-programs',
    'documentary-series-or-special',
    'limited-series',
    'television-movie',
    'hosted-nonfiction-series-or-special',
    'animated-programs',
    'reality-series',
]

export default VisibleCategories;