import React, { useLayoutEffect } from 'react'
import { Event, TrackingEvents } from '../../utils/Tracking'

const ReturnToShow = ({categorySlug, titleSlug, titleName}) => {

    useLayoutEffect( () => {
      let subscribed = true;
        if (subscribed) {

        }
      return ()=>{subscribed=false}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

      <div className="subnav__container d-flex">
          <div className="d-flex align-self-stretch align-items-stretch justify-content-center w-100">
            <a onClick={() => {
              Event(TrackingEvents.categories.INTERNAL_NAVIGATION, TrackingEvents.actions.NAV_CATEGORY_SUBNAV, TrackingEvents.labels.NAV_CATEGORY_SUBNAV)
            }}
            className="subnav__container--return d-flex align-items-center"
            href={ "/" + categorySlug + "/" + titleSlug }
            style={{textTransform:'capitalize',color:"#f5f5f5"}}>
              <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-0 me-md-2"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
              <span className="d-none d-md-flex" style={{color:"#f5f5f5"}}>{ titleName }</span>
            </a>

          </div>
      </div>

    )
}

export default ReturnToShow
