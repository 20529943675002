import React, { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import { Event, TrackingEvents } from '../../utils/Tracking'

const ReturnToMedia = ({categorySlug, titleSlug, titleName}) => {

    const match = useParams()

    const [mediaSection, setMediaSection] = useState(null),
        [mediaSectionName, setMediaSectionName] = useState(null)

    useEffect( () => {
        let subscribed=true
        if (subscribed && match) {
            setMediaSection(match.mediaSection)
            setMediaSectionName(match.mediaSection==="read"?"scripts":match.mediaSection)
          }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ categorySlug, titleSlug, titleName, match ])

    return (

      <div className="subnav__container d-flex">
          <div className="d-flex align-self-stretch align-items-stretch justify-content-center w-100">
            <a onClick={() => {
              Event(TrackingEvents.categories.INTERNAL_NAVIGATION, TrackingEvents.actions.NAV_CATEGORY_SUBNAV, TrackingEvents.labels.NAV_CATEGORY_SUBNAV)
            }}
            className="subnav__container--return d-flex align-items-center"
            style={{textTransform:'capitalize',color:"#f5f5f5"}}
            href={ "/" + categorySlug + "/" + titleSlug + "/" + mediaSection } >
              <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-0 me-md-2"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
              <span className="d-none d-md-flex" style={{color:"#f5f5f5"}}>{titleName}&nbsp; <span style={{textTransform:'capitalize'}}>{ mediaSectionName }</span></span>
            </a>

          </div>
      </div>

    )
}

export default ReturnToMedia
