import React, {useEffect, useState} from 'react'
import mediaPlaceholder from '../../../Common/_assets/img/placeholder/photo-placeholder.png'
import mediaPlaceholderVertical from '../../../Common/_assets/img/placeholder/spacer_portrait_series.gif'
import thumbPlaceholder from '../../../Common/_assets/img/placeholder/video-placeholder-n.jpg'
import scriptPlaceholder from '../../../Common/_assets/img/bg/script-blank.png'
// import Media from "../../../Common/lib/Media/Media"

const TitleMediaItem = ({mediaItem, categorySlug, titleSlug, mediaSection, i}) => {

    const [thumbnail, setThumbnail] = useState(null),
          [mediaType, setMediaType] = useState(""),
          [title, setTitle] = useState(""),
          [description, setDescription] = useState(""),
          [url, setUrl] = useState(""),
          [mediaSlug, setMediaSlug] = useState(null),
          [isNew, setIsNew] = useState(false)

    const today = new Date()

    const s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/"


    useEffect( () => {
        let subscribed=true



        if (subscribed && mediaItem) {

                // console.log('media item',mediaItem,s3Url+mediaItem.thumbnail)
              setTitle(mediaItem.title.replace('.pdf','').replace('.PDF',''))
              setDescription(mediaItem.description ? mediaItem.description : null)
              setMediaSlug(mediaItem.media_slug ? mediaItem.media_slug : mediaItem.slug)
              let mediaDateModified = new Date(mediaItem.modified)
              let diffTime = today.getTime() - mediaDateModified.getTime()
              let diffDays = diffTime / (1000 * 3600 * 24)
              setIsNew(diffDays <=7 ? true : false)


              if (mediaItem.type) {

                setMediaType(mediaItem.type)

                if (mediaSection === 'videos') {

                    // const slugToUse = mediaItem.media_slug ? mediaItem.media_slug : mediaItem.slug

                    // Media.getMediaThumbnail(slugToUse, result => {
                    //   if (!result.error && Object.keys(result.data).length>0) {
                    //       setThumbnail(s3Url+result.data.filename)
                    //   } else if (mediaItem.filename) {
                    //       setThumbnail('https://i.ytimg.com/vi/'+mediaItem.filename+'/maxresdefault.jpg')
                    //   } else {
                    //       setThumbnail(thumbPlaceholder)
                    //   }
                    // })
                    setThumbnail(s3Url+mediaItem.thumbnail)
                    // console.log('thumbnail',thumbnail)

                } else if (mediaSection === 'images') {

                    setThumbnail(s3Url+mediaItem.filename)

                } else if (mediaSection === 'read' || mediaSection === 'presentations') {
                    if (mediaItem.type === 'application/pdf') {
                      mediaItem.thumbnail ? setThumbnail(s3Url+mediaItem.thumbnail) : setThumbnail(scriptPlaceholder)
                    }
                } else {
                  setThumbnail(thumbPlaceholder)
                }

            } else  if (mediaSection==='press') {
                setMediaType('link')
                setThumbnail(s3Url+mediaItem.thumbnail)
                setTitle(mediaItem.label)
                setDescription(mediaItem.description)
                setUrl(mediaItem.url)

            } else {
                setThumbnail(s3Url+mediaItem.thumbnail)
                setMediaType('youtube')
            }


        }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaItem, mediaSection])


  if (mediaType === 'application/pdf') {

    return (
      <>
        <a href={'/'+categorySlug+'/'+titleSlug+'/read/'+mediaSlug}
            className="titledetail__mediaitem d-block d-md-none">

          <div className="titledetail__mediaitem--thumbnail-container">

              <img src={mediaPlaceholder} alt={title} className="titledetail__mediaitem--placeholder" />

            {thumbnail ?
              <img src={thumbnail} alt={title} className={"titledetail__mediaitem--thumbnail "+(mediaSection==='read' || mediaSection==='images' ? "titledetail__mediaitem--contained" : "")} />
              :""
            }

          </div>

          {
              mediaType==="video/mp4" || mediaType==="youtube" || mediaType==="application/pdf" ?
              <div className="titledetail__mediaitem--title text-center mt-2">
                {title}
                {isNew ?
                  <div className="d-none text-red text-uppercase font-weight-bold ms-1 label-new text-right">
                    NEW
                  </div>
                :""}
              </div>
              :""

          }

        </a>

        <a href={"/"+categorySlug+"/"+titleSlug+"/"+mediaSection+"/"+mediaSlug}
            className="titledetail__mediaitem d-none d-md-block">
          <div className="titledetail__mediaitem--thumbnail-container">
              <img src={mediaPlaceholder} alt={title} className="titledetail__mediaitem--placeholder" />
            {thumbnail ?
              <img src={thumbnail} alt={title} className={"titledetail__mediaitem--thumbnail "+(mediaSection==='read' || mediaSection==='images' ? "titledetail__mediaitem--contained" : "")} />
              :""
            }
          </div>


          {
              mediaType==="video/mp4" || mediaType==="youtube" || mediaType==="application/pdf" ?
              <div className="titledetail__mediaitem--title text-center mt-2">
                {title}
                {isNew ?
                  <div className="d-none text-red text-uppercase font-weight-bold ms-1 label-new text-right">
                    NEW
                  </div>
                :""}
              </div>
              :""

          }

        </a>
      </>

    )

  } else {

    return (
      <a href={ mediaSection==='press'  ? url
        : "/"+categorySlug+"/"+titleSlug+"/"+mediaSection+"/"+mediaSlug
      }
          target={mediaSection==='press' ? "_blank" : "_self"}
          rel="noopener noreferrer"
          className="titledetail__mediaitem d-block">
        <div className="titledetail__mediaitem--thumbnail-container" style={{
            backgroundImage:'url("'+(thumbnail ? thumbnail : null)+'")',
            backgroundSize:mediaSection==='press'?'contain':'cover',
            backgroundPosition:mediaSection==='press'?'center bottom':'center center',
            backgroundRepeat:'no-repeat'
        }}>
            {
                mediaSection==='press'?
                    <img src={mediaPlaceholderVertical} alt={title} className="titledetail__mediaitem--placeholder" />
                :
                    <img src={mediaPlaceholder} alt={title} className="titledetail__mediaitem--placeholder" />
            }
        </div>

        {
            mediaType==="video/mp4" || mediaType==="youtube" || mediaType==="application/pdf" ?
            <div className="titledetail__mediaitem--title text-center mt-2">
              {title}
              {isNew ?
                <div className="d-none text-red text-uppercase font-weight-bold ms-1 label-new text-right">
                  NEW
                </div>
              :""}
            </div>
            :""

        }
        {
            mediaType==="link" ?
            <>
            <div className="titledetail__mediaitem--title text-center mt-2">
              {title}
            </div>
            {
                description ?
                    <div className="titledetail__mediaitem--description mt-2">
                      {description}
                    </div>
                : null
            }

            </>
            :""

        }

      </a>
    )

  }
}

export default TitleMediaItem
