import React from 'react'


const TitleMediaList = ({titleSlug, categorySlug, mediaList, mediaSection}) => {

    return (
        <>
            <div className="pe-0 pe-md-5 mb-5 position-relative w-100">
                <div className="">
                    <div className="p-3">

                        <div className="row justify-content-center">

                            {
                                mediaList ?
                                    mediaList.map( (m, i) => {
                                        return (
                                            <a href={m.url} key={i} target="_blank" rel="noopener noreferrer" className="titledetail__mediaitem titledetail__medialistitem col-12 py-3">
                                                <div className="titledetail__mediaitem--title " style={{fontWeight:'bold'}}>
                                                  {m.label}
                                                </div>
                                                <div className="titledetail__mediaitem--description mt-2" style={{fontSize:'.8rem'}}>
                                                  {m.description}
                                                </div>
                                            </a>
                                        )
                                    })
                                : null
                            }

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TitleMediaList
