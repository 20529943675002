import React, {useLayoutEffect, useState} from 'react'

const TitleMediaMp3PlaylistItem = ({currentTrack,setCurrentTrack,setPlaylistTrack,assetObject}) => {

    const [track, setTrack] = useState()
    const [mediaKey, setMediaKey] = useState()



    useLayoutEffect( () => {
        let subscribed=true
        let s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/"
        if (subscribed) {
          setTrack(assetObject)
          setMediaKey(s3Url+assetObject.filename)
        }

    }, [assetObject, setPlaylistTrack])


    return (

      <>

        <div className={"titledetail__asset--section  titledetail__audio--container  "}>

            {(track)?(
              <div className="d-flex flex-wrap no-gutters align-items-center text-left">

                <div className="col-12 text-left font-bold">
                  <button onClick={()=>{
                    setPlaylistTrack(mediaKey)
                    setCurrentTrack(mediaKey);
                  }}>
                    {(currentTrack === (mediaKey))?(
                      <span className="titledetail__audio--icon titledetail__audio--icon-highlighted icon material-icons">radio_button_checked</span>
                    ):(
                      <span className="titledetail__audio--icon  icon material-icons">radio_button_unchecked</span>
                    )}
                    <span style={{textTransform:'capitalize'}}>{track.title.replace(/-/g, ' ').replace('.mpeg', '')}</span></button>
                </div>

              </div>
            ):("")}

        </div>

      </>

    )
}

export default TitleMediaMp3PlaylistItem
