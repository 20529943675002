import React, {useLayoutEffect, useState} from 'react'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const TitleMediaMp3Single = ({track}) => {


    const [mediaKey, setMediaKey] = useState()



    useLayoutEffect( () => {
        let subscribed=true
        let s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/"
        if (subscribed) {
          setMediaKey(s3Url+track.filename)
        }

    }, [track])


    return (

      <>

        <div className={"titledetail__asset--section  titledetail__audio--container  mb-4"}>

            {(mediaKey)?(
              <div className="d-flex flex-wrap no-gutters align-items-center text-left">
                <div className="col-12">



                  <AudioPlayer
                    autoPlay
                    src={mediaKey}
                  />


                </div>
                <div className="col-12 pt-3 pb-2 text-left font-bold" style={{textTransform:'capitalize'}}>
                  {track.title.replace(/-/g, ' ')}
                </div>

              </div>
            ):("")}

        </div>

      </>

    )
}

export default TitleMediaMp3Single
