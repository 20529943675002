import React from 'react'
import TitleMediaItem from './TitleMediaItem'

const TitleMediaGridItem = ({mediaItem, categorySlug, titleSlug, mediaSection, i}) => {

    return (

      <div className="col-6 col-md-4 p-0 mb-4 pe-4 titledetail__mediaitem--container position-relative">
        <TitleMediaItem i={i} mediaItem={mediaItem} categorySlug={categorySlug} titleSlug={titleSlug} mediaSection={mediaSection} />
      </div>

    )
}

export default TitleMediaGridItem
