import React from 'react'
import TitleMediaMp3Playlist from './TitleMediaMp3Playlist'
import TitleMediaMp3Single from './TitleMediaMp3Single'

const TitleMediaAudio = ({taglist_mp3album, taglist_mp3single, taglist_spotify}) => {



    return (

      <div className="py-3" >

      { taglist_spotify ?
        <div className="d-block">
          {taglist_spotify.map((asset,i) => {
            if (asset.type === 'playlists') {
              return (
                <div className="titledetail__asset--section mb-5" key={ i }>
                  <iframe title="iframe" src={"https://open.spotify.com/embed/playlist/"+asset.filename} width="100%" height="" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
              )
            } else if (asset.type === 'albums') {
              return (
                <div className="titledetail__asset--section mb-5" key={ i }>
                  <iframe title="iframe" src={"https://open.spotify.com/embed/album/"+asset.filename} width="100%" height="" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
              )
            } else if (asset.type === 'episodes') {
              return (
                <div className="titledetail__asset--section mb-5" key={ i }>
                  <iframe title="iframe" src={"https://open.spotify.com/embed/episode/"+asset.filename} width="100%" height="" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
              )
            } else if (asset.type === 'tracks') {
              return (
                <div className="titledetail__asset--section mb-5" key={ i }>
                  <iframe title="iframe" src={"https://open.spotify.com/embed/track/"+asset.filename} width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
              )
            } else {
              return ""
            }

          })}
          </div>
        :""
      }

      {
        taglist_mp3single ?
          taglist_mp3single.map((o,i)=>{
            return (
              <>
              <TitleMediaMp3Single
                key={i}
                track={o} />
              </>
            )
          })

        :""
      }

      {
        taglist_mp3album ?
        <>
          <TitleMediaMp3Playlist
            album={taglist_mp3album} />
        </>
        :""
      }



      </div>

    )
}

export default TitleMediaAudio
