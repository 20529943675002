import React, { useLayoutEffect, useState } from 'react'

import Moment from 'react-moment'
import 'moment-timezone'

// import Venue from '../../Common/lib/Venue/Venue'
// import Event from '../../Common/lib/Event/Event'

import spacerSquare from '../../Common/_assets/img/placeholder/spacer_portrait_series.gif'
import fyseePlaceholder from '../../Common/_assets/img/placeholder/Netflix_FYSEE_placeholder.jpg'
import shadowImage from '../../Common/_assets/img/bg/events-thumbmail-shadow.png'
import fyseeSpacer from "../../Common/_assets/img/placeholder/emy_miniposter_placeholder.png";

const EventExcerpt = ({ event_object }) => {

    const [startDate, setStartDate] = useState(null),
        [startDateNum, setStartDateNum] = useState(null),
        [startTime, setStartTime] = useState(null),
        [eventTitle, setEventTitle] = useState(''),
        [eventDescription, setEventDescription] = useState(''),
        [eventSlug, setEventSlug] = useState(''),
        [venueTimezone, setVenueTimezone] = useState(null),
        [venueName, setVenueName] = useState(null),
        [venueAddress, setVenueAddress] = useState(null),
        [venueCity, setVenueCity] = useState(null),
        [eventShows, setEventShows] = useState([]),
        [eventMediaSrc, setEventMediaSrc] = useState(null)

    const s3Url = 'https://jwlfyc-public-us-east-1.s3.amazonaws.com/'


    useLayoutEffect( () => {
        let subscribed = true
        if (subscribed && event_object) {

                // console.log('event_object',event_object)
            Moment.globalTimezone = 'America/Los_Angeles'
            Moment.globalLocale = 'en-us'
            setEventTitle(event_object.title)
            setEventDescription(event_object.description)
            setEventSlug(event_object.slug)
            setVenueName(event_object.venue_name)
            setVenueAddress(event_object.venue_address)
            setVenueCity(event_object.venue_city)
            setVenueTimezone(event_object.venue_timezone)


            const tempDateTime = event_object.start_date.split('T')[0]+'T12:00:00.000'
            setStartDate(tempDateTime)
            setStartDateNum(event_object.start_date.split('T')[0].split('-')[2])
            let hours = event_object.start_time.split(':')[0]+':'+event_object.start_time.split(':')[1]
            let hoursFormatted = tConvert(hours)
            setStartTime(hoursFormatted)


            // let uniqueShows = event_object.shows.split(',').filter(function(item, pos, self) {
            //     return self.indexOf(item) === pos;
            // })
            // console.log('shows',event_object.shows)
            let uniqueShows = event_object.shows.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.title === value.title
                    ))
            )
            let sortedUniqueShows = sorter(uniqueShows)
            // console.log('sorted shows',sortedUniqueShows)

            setEventShows(sortedUniqueShows)


            if (Object.keys(sortedUniqueShows).length===1) {
                if (event_object.slug==='1722262992' || event_object.slug==='1722625525') {
                    setEventMediaSrc('https://jwlfyc-public-us-east-1.s3.amazonaws.com/1722469670493-emy_miniposter_samurai.jpg')
                } else {
                    setEventMediaSrc(s3Url + event_object.filename)
                }
            } else {
                setEventMediaSrc(fyseePlaceholder)
            }

        } return ()=>{subscribed = false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ event_object ])

    const sorter = (array)=> {
        array.sort(function(a, b){
            var nameA = a.title.replace("The ", "").toLowerCase(),
                nameB = b.title.replace("The ", "").toLowerCase();
            // console.log(nameA, nameB)
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0; //default return value (no sorting)
        });
        return array
    }

    const tConvert = (time) => {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
          time = time.slice(1); // Remove full string match value
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
  }


    return (

        <div className="col-12 col-md-3 mb-5 event__excerpt--container">

            <div className="event__excerpt--inner">
                <div className="event__excerpt--top d-flex flex-column " style={{
                    backgroundImage:"url("+eventMediaSrc+")",
                    backgroundSize:"contain",
                    backgroundPosition:"center",
                    backgroundRepeat:"no-repeat",
                    backgroundColor:"rgba(0,0,0,.8)"
                }}>
                    <a href={"/events/"+eventSlug}>
                        <div className="event__excerpt--top-inner d-flex p-3 h-100">

                            {startDate ?
                                <div className="d-flex flex-column ms-auto">
                                    <div className="event__excerpt--date-dayname text-center"><Moment format="dddd">{startDate}</Moment></div>
                                    <div className="event__excerpt--date-day text-center">{startDateNum}</div>
                                    <div className="event__excerpt--date-month text-center"><Moment format="MMM">{startDate}</Moment></div>
                                </div>
                                : ""}


                        </div>
                        <div className="event__excerpt--top-shadow">
                            <img src={shadowImage} className=" eventthumbnail__spacer" alt="Netflix" />
                        </div>

                        {
                            (eventSlug === '1722262992' || eventSlug==='1722625525') ?
                                <img src={fyseeSpacer} className=" eventthumbnail__spacer" alt="Netflix"/>
                                :
                                <img src={spacerSquare} className=" eventthumbnail__spacer" alt="Netflix"/>

                        }

                            { eventShows && (eventSlug !== '1722262992' && eventSlug !== '1722625525') ?
                                <div className="w-100 px-3 pb-3 text-center" style={{
                                    position:'absolute',
                                    bottom:0,
                                    left:'50%',
                                    transform:'translateX(-50%)'
                                }}>
                                    {
                                        eventShows.map((o,i )=> {
                                            return <span key={i} className="event__excerpt--show">{i>0?', ':''} {o.title}</span>
                                        })
                                    }

                                </div>
                            : null }


                    </a>
                </div>
                <div className="event__excerpt--bottom d-flex flex-column p-3">


                    <div className="event__excerpt--title"><a href={"/events/"+eventSlug}>{eventTitle}</a></div>

                    <div className=" mt-3">

                        {startDate ?
                            <div className="mb-3">
                                <div className="mb-1"><span><svg viewBox="0 0 24 24" width="12" height="12" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-2"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg></span><Moment format="dddd MMMM D, YYYY">{startDate}</Moment></div>
                                <div className=""><span><svg viewBox="0 0 24 24" width="12" height="12" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-2"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg></span>{startTime}&nbsp;
                                {venueTimezone}</div>
                            </div>
                            :""}

                        <div className=" mb-1">
                            <div className="event__excerpt--venue mb-3">
                                <div className="font-weight-bold mb-1" style={{fontWeight: '600'}}><span><svg
                                    viewBox="0 0 24 24" width="12" height="12" stroke="currentColor"
                                    strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                    className="me-2"><rect x="3" y="4" width="18" height="18" rx="2"
                                                           ry="2"></rect><line x1="16" y1="2" x2="16"
                                                                               y2="6"></line><line x1="8" y1="2"
                                                                                                   x2="8"
                                                                                                   y2="6"></line><line
                                    x1="3" y1="10" x2="21" y2="10"></line></svg></span>{venueName}</div>
                                <div className="">{venueAddress}, {venueCity}</div>
                            </div>
                        </div>


                    </div>

                    <div className="d-none event__excerpt--description mt-3 mb-3" dangerouslySetInnerHTML={{ __html: eventDescription }} />

                    <div className="mt-auto">
                        <a href={"/events/"+eventSlug}>
                            <button className="button__rsvp button__box button__icon--right highlighted"><span className="button__text">View Details / RSVP</span><span className="icon material-icons">keyboard_arrow_right</span></button>
                        </a>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default EventExcerpt
