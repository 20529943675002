import { useEffect, useState } from 'react';
import {
    Route,
    BrowserRouter as Router,
    Routes
} from "react-router-dom"
import ReactGA from "react-ga4"
import Header from './Components/Header/Header'
import Home from './Components/Home/Home'
import Search from './Components/Search/Search'
import Page from './Components/Page/Page'
import Category from './Components/Category/Category'
import TitleDetail from './Components/TitleDetail/TitleDetail'
import EventList from './Components/Event/EventList'
import EventDetail from './Components/Event/EventDetail'
import UpdateRSVP from './Components/Page/UpdateRSVP'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './Common/_assets/styles/main.scss'

function App() {

    const [showSearch, setShowSearch] = useState(false)

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            ReactGA.initialize([
                {
                    trackingId: "G-S371Q8G4DD"
                },
                {
                    trackingId: "DC-4968236"
                }
            ]);
            ReactGA.event({
                category: "conversion",
                allow_custom_scripts: true,
                send_to: 'DC-4968236/awardfyc/dcmna00+standard'
            });
            ReactGA.send("pageview");
        }
        return ()=>{subscribed = false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="root" className="d-flex flex-column">

            <main id="mainContainer" className="main__container container-fluid d-flex flex-column align-items-stretch">

                <Header setShowSearch={setShowSearch}/>

                <Router>
                    <Routes>
                        <Route path={'/'} element={<Home/>}/>
                        <Route path={ '/events' } element={ <EventList /> } />
                        <Route path={ '/events/:eventSlug' } element={ <EventDetail /> } />
                        <Route path={ '/updatersvp/' } element={ <UpdateRSVP /> } />
                        <Route path={ '/updatersvp/:hash' } element={ <UpdateRSVP /> } />
                        <Route path={ '/privacy' } element={ <Page thisPageName="privacy" /> } />
                        <Route path={ '/:categorySlug' } element={ <Category /> } />
                        <Route path={ '/:categorySlug/:titleSlug/*' } element={ <TitleDetail /> } />
=                       <Route path={ '/*' } element={ <p>Not Found</p> } />
                    </Routes>
                </Router>

            </main>

            {
                showSearch ?
                    <div className="search__container d-flex align-items-center justify-content-center">
                        <Search setShowSearch={setShowSearch}/>
                    </div>
                    : ""
            }

        </div>
    );
}

export default App;
