import React, { useEffect, useState, useRef } from 'react'
import Sidebar from './Sidebar/Sidebar'
import FooterSmall from '../Footer/FooterSmall'
import $ from 'jquery'
import { isMobile, isTablet } from "react-device-detect"

// import fyseeBackgroundVideo from "../../Common/_assets/img/bg/streams-gold.mp4"
// import fyseeBackgroundPoster from "../../Common/_assets/img/bg/streams-gold.jpg"
// import fyseeLogo from "../../Common/_assets/img/logo/logo-fysee24-2.png"
// import fysee24Video from "../../Common/_assets/video/fysee24.mp4"
// import fysee24Poster from "../../Common/_assets/video/fysee24.jpg"
// import fysee24Poster from "../../Common/_assets/video/fysee24loading.jpg"
import {getLandingPageData} from "../../utils/api/helpers/LandingPage";
import fysee24CrownImage from "../../Common/_assets/img/placeholder/CROWN-S6_1920x1080_ADVERTORIALGRAPHIC-TOPPER.jpg";

const Home = () => {

    const [videoPlaying, setVideoPlaying] = useState(false),
          [backgroundVideo, setBackgroundVideo] = useState(null),
          [backgroundPoster, setBackgroundPoster] = useState(null),
          [titleTreatment, setTitleTreatment] = useState(null),
          [showGenreSlug, setShowGenreSlug] = useState(null),
          [showSlug, setShowSlug] = useState(null),
          [backgroundLoaded, setBackgroundLoaded] = useState(false),
          [showFysee, setShowFysee] = useState(false),
          [categories, setCategories] = useState([])

          // [eventList, setEventList] = useState([])

    const videoRef = useRef()
    const fysee24VideoRef = useRef()
    const s3Url = 'https://jwlfyc-public-us-east-1.s3.amazonaws.com/';
    // const fysee24Video = s3Url+'1716505095201-fysee24.mp4'
    // const fysee24Poster = s3Url+'1716505115162-fysee24.jpg'

    const storageShowFysee = window.localStorage.getItem('showFysee')

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            let referrer = document.referrer.split('/')[2]
            if ((referrer==='series.netflixawards.com' || referrer==='localhost:7002' || referrer==='hybrid-dev-series-fyc-jsopervendor.us-east-1.nq.test.netflixstudios.com' || referrer==='series-fyc.staging.netflixstudios.com') && storageShowFysee==='false') {
                setShowFysee(false)
            } else {
                setShowFysee(true)
            }
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ storageShowFysee ])

    const buildGenreSlug = (genres) => {
        let genre = JSON.parse('['+genres+']')[0]
        if (genre.slug.includes('limited') || genre.slug.includes('television')) {
          return 'limited-series-television-movie'
        } else if (genre.slug.includes('documentary')) {
          return 'documentary-series-or-special'
        } else if (genre.slug.includes('variety')) {
          return 'variety'
        } else {
          return genre.slug
        }
    }

    useEffect( () => {
        let subscribed = true
        if (subscribed && !backgroundLoaded) {
            getLandingPageData()
                .then(result => {
                    // console.log("RE", result.data);
                    setCategories(result.data.GenreList)
                    if (result.data.vidList.length) {
                        let randIndex = Math.floor(Math.random() * result.data.vidList.length)
                        setBackgroundVideo(s3Url + result.data.vidList[randIndex].bgVideo)
                        setBackgroundPoster(s3Url + result.data.vidList[randIndex].thumbnail)
                        setTitleTreatment(s3Url + result.data.vidList[randIndex].titleTreatment)
                        setShowGenreSlug(buildGenreSlug(result.data.vidList[randIndex].genre))
                        setShowSlug(result.data.vidList[randIndex].slug)
                        setBackgroundLoaded(true)
                    }
                })
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundLoaded])

    useEffect( () => {
        let subscribed = true
        if (subscribed && videoRef && backgroundLoaded && !showFysee) {
            setTimeout(()=>{
              if (videoRef.current) videoRef.current.play()
              setVideoPlaying(true)
            },200)
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ videoRef, backgroundLoaded, showFysee ])

    useEffect( () => {
        let subscribed = true
        if (subscribed && fysee24VideoRef && showFysee) {
            setTimeout(()=>{
                if (fysee24VideoRef.current) fysee24VideoRef.current.play()
            },200)
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ fysee24VideoRef, showFysee ])

    const pauseVideo = () => {
      if (videoRef.current) videoRef.current.pause()
      setVideoPlaying(false)
    }
    const playVideo = () => {
      if (videoRef.current) videoRef.current.play()
      setVideoPlaying(true)
    }

    useEffect( () => {
      let subscribed = true;
      if (subscribed && backgroundLoaded) {

        $(document).on('mouseenter', '.sidebar__container--list li', function (e) {
          $('.sidebar__container--list li a').removeClass('active')
          $('.sidebar__container--ctalist').addClass('active')
          $('.homepage__cta--container').addClass('active')
          $('.main__background').addClass('active')
          $('.main__background--info').addClass('active')
          $('.fysee__background').addClass('active')
          $('.fysee__content').addClass('active')
          $(e.target).addClass('active')
        });
        $(document).on('mouseleave', '.sidebar__container--list li', function (e) {
          $('.sidebar__container--ctalist').removeClass('active')
          $('.homepage__cta--container').removeClass('active')
          $('.main__background').removeClass('active')
          $('.main__background--info').removeClass('active')
          $('.fysee__background').removeClass('active')
          $('.fysee__content').removeClass('active')
        });
        $(document).on('mouseenter', '.sidebar__list--flyout-item', function () {
          $('.main__background').addClass('active')
          $('.main__background--info').addClass('active')
          $('.fysee__background').addClass('active')
          $('.fysee__content').addClass('active')
        });
        $(document).on('mouseleave', '.sidebar__list--flyout-item', function () {
          $('.main__background').removeClass('active')
          $('.main__background--info').removeClass('active')
          $('.fysee__background').removeClass('active')
          $('.fysee__content').removeClass('active')
        });

        $(document).ready(function(){
          $("#mainBackgroundVideo").on(
            "timeupdate",
            function(event){
              onTrackedVideoFrame(this.currentTime, this.duration);
            });
        });

        function onTrackedVideoFrame(currentTime, duration){
          let percentage = currentTime / duration * 100;
          $('#videoBar1').css({'width':percentage+'%'})
        }

      }
      return ()=>{subscribed=false}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backgroundLoaded])

    return (

      <>
        <div className="content__container d-flex flex-fill ">
          <div className="width_ctrl row no-gutters flex-fill">

            <Sidebar categories={categories} showFysee={showFysee} setShowFysee={setShowFysee} videoPlaying={videoPlaying} playVideo={playVideo} pauseVideo={pauseVideo} />

            <div className={(showFysee ? "d-flex" : "d-none d-md-flex") + " col-12 " + (isMobile ? ' col-md-12' : ' col-md-10') + " main__content flex-column justify-content-center align-items-center"} style={{paddingBottom:'6rem'}}>

              {
                showFysee ?
                  <div className="fysee__content mt-5 pt-5 mt-md-0 px-0 px-md-5" style={{width:'100%'}}>
                      <a href='https://www.thecrownretrospective.com/' target={'blank'} rel={'noopeneer noreferrer'} className="">
                          <div className="home__image--container d-flex justify-content-center align-items-center">
                              <img src={fysee24CrownImage} alt={"The Crown"} className={'home__image'} style={{maxHeight:'70vh'}}/>
                          </div>
                      </a>
                      <div className="mt-4 mb-5 d-flex flex-column flex-md-row justify-content-center">


                          <button className="d-none button__outline button__icon--right px-3 justify-content-center mb-2 ">
                              <a
                                  href="/events/">View All FYSEE Events <span className="ms-1 icon"
                                                                              style={{transform: 'translateY(-2px)'}}><svg
                                  viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2"
                                  fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><rect
                                  x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16"
                                                                                                y2="6"></line><line
                                  x1="8"
                                  y1="2"
                                  x2="8"
                                  y2="6"></line><line
                                  x1="3" y1="10" x2="21" y2="10"></line></svg></span></a></button>

                          <button onClick={() => {
                              setShowFysee(false)
                              // window.localStorage.setItem('showFysee',false)
                              setTimeout(()=>{
                                videoPlaying ? playVideo() : pauseVideo()
                              }, 200)
                          }}
                                  className="button__outline button__icon--right px-3  justify-content-center  mb-2 ms-0 ms-md-3">Continue
                              to Netflix FYC<span className="ms-1 icon" style={{transform: 'translateY(-2px)'}}><svg
                                  viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2"
                                  fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line
                                  x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>
                          </button>
                      </div>
                  </div>
                    : ""
              }


            </div>

          </div>
        </div>


          <div className="d-none d-md-block main__background--controls" onClick={() => {
              videoPlaying ? pauseVideo() : playVideo()
          }}>
              {videoPlaying ?
                  <svg viewBox="0 0 24 24" width="48" height="48" stroke="currentColor" strokeWidth="2" fill="none"
                       strokeLinecap="round" strokeLinejoin="round" className="">
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="10" y1="15" x2="10" y2="9"></line>
                      <line x1="14" y1="15" x2="14" y2="9"></line>
                  </svg>
                  :
                  <svg viewBox="0 0 24 24" width="48" height="48" stroke="currentColor" strokeWidth="2" fill="none"
                       strokeLinecap="round" strokeLinejoin="round" className="">
                      <circle cx="12" cy="12" r="10"></circle>
                      <polygon points="10 8 16 12 10 16 10 8"></polygon>
                  </svg>
              }
              <div className="main__background--controls-tip arrowbox__grey">
            {videoPlaying ? "Pause Video" : "Play Video"}
          </div>
        </div>



        {
            (!showFysee && backgroundLoaded) ?

                <>
                  {
                    !isMobile && !isTablet ?
                      <div className="main__background--info d-none d-md-flex flex-column align-items-center justify-content-center">
                          <img src={titleTreatment} alt="Netflix" className="mb-4 main__background--info-tt" />
                          <a href={'/'+showGenreSlug+'/'+showSlug}><button className="button__box button__icon--right"><span className="button__text">Learn More </span><span className="icon ps-2" style={{marginTop:'-2px'}}><svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="9 18 15 12 9 6"></polyline></svg></span></button></a>
                      </div>
                    : null
                  }


                    <div className="main__background no-default">
                        <video ref={videoRef} poster={backgroundPoster} playsInline muted loop id="mainBackgroundVideo">
                            <source src={backgroundVideo}></source>
                        </video>
                    </div>

                    <div className="video-indicator">
                        <div id="videoBar1" className="video-bar">
                            <div className="time"></div>
                            <div className="duration"></div>
                            <div className="percentage"></div>
                        </div>
                    </div>
                </>

            : null
        }



        <div className="main__gradient main__gradient-home" />

        <FooterSmall />

      </>

    )
}

export default Home
