import React, {useRef, useLayoutEffect} from 'react'
import { Link } from 'react-router-dom'
import YouTube from 'react-youtube'
import $ from 'jquery'
import iconPlay from '../../../../Common/_assets/img/icon/icon-play.svg'
import videoPlaceholderBlank from '../../../../Common/_assets/img/placeholder/videoplaceholderblank.gif'

const YouTubeFull = ({id, mediaPosterUrl, title, description, isFysee, crafts, playlists}) => {

    const videoThumbRef = useRef()
    const videoFullRef = useRef()
    const videoFullContainer = useRef()

    const vidOpts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            controls: 1,
            cc_load_policy: 0,
            fs: 1,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
            playsinline:1
        },
    };

    useLayoutEffect( () => {
        let subscribed=true
        if (subscribed && id) {
            // playVideo()
        }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const playVideo = () => {
      videoFullRef.current.internalPlayer.seekTo(1)
      videoFullRef.current.internalPlayer.playVideo()
      setTimeout(()=>{
          $(videoThumbRef.current).addClass('disabled')
      }, 600)
      $(videoFullContainer.current).removeClass('disabled')
    }

    const onVideoFullEnd = () => {
        $(videoThumbRef.current).removeClass('disabled')
        setTimeout(()=>{
            $(videoFullContainer.current).addClass('disabled')
        }, 600)
    }

    const onVideoReady = () => {
        if (videoFullRef.current)
            playVideo()
    }


    return (


      <div className="col-12">

        <div className="fyseevideo__container  ">

          <div className="fyseevideo__inner">

              {(id)?(
                  <div className="videothumb__container" ref={videoThumbRef}>
                  <div className="video__container" style={{overflow:'hidden'}}>
                  <Link className=""  onClick={(e)=>{e.preventDefault();playVideo()}} to="/">
                    <img src={iconPlay} className="icon__play" alt="FYSEE" />
                    {(mediaPosterUrl)?(
                      <img src={mediaPosterUrl} className="videothumbnail__image videothumb__cursor" alt="FYSEE" />
                    ):("")}
                  <img src={videoPlaceholderBlank} className="" alt="Netflix FYC" />
                  </Link>
                  </div>
                  </div>
              ):("")}


              {(id)?(

                <div className="videofull__container disabled" ref={videoFullContainer}>
                  <div className="video__container">
                    <YouTube
                      id="fyseeyoutubevideo"
                      className="videofull__video"
                      ref={videoFullRef}
                      onReady={onVideoReady}
                      onEnd={onVideoFullEnd}
                      videoId={id}
                      opts={vidOpts} />
                  </div>
                </div>

              ):("")}


        </div>

        </div>

        <div className=" titledetail__mediaalbum--title text-left mt-3 mb-2 font-weight-bold  " style={{color:'#f9f9f9',fontSize:'1.5rem',lineHeight:'1.2em'}}>
          {title}
        </div>
          {
              isFysee ?
                  <div className={'label-small w-100 mb-2'} style={{textTransform:'uppercase',fontWeight:600,fontSize:'.7rem',color:'#999'}}>
                      {
                          playlists ?
                              <>
                                  {
                                      playlists.map((o,i)=>{
                                          return <span key={i}>{i>0?', ':''}{o.name}</span>
                                      })
                                  }
                              </>
                              : null
                      }
                      {
                          crafts ?
                            <>
                                <> - </>
                                {
                                  crafts.map((o,i)=>{
                                      return <span key={i}>{i>0?', ':''}{o.name}</span>
                                  })
                                }
                            </>
                          : null
                      }
                  </div>

                  : null
          }
        <div className=" titledetail__mediaalbum--description text-left" dangerouslySetInnerHTML={{__html: description }} />

        <div style={{height:'6rem'}}/>



      </div>


    )
}

export default YouTubeFull
