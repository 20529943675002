import React, { useState, useRef, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import $ from 'jquery'
import iconSearch from '../../Common/_assets/img/icon/mat-icon-search.png'
// import Show from "../../Common/lib/Show/Show";

import {search} from "../../utils/api/helpers/utils";

const Search = ({searchTerm, setSearchTerm, setShowSearch}) => {

    const [ isSearching, setIsSearching ] = useState( false ),
        [ searchVal, setSearchVal ] = useState( "" ),
        [ list, setList ] = useState( [] )

    const searchInputRef = useRef(null)

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            setList([])
            searchInputRef.current.focus()
            $(document).on('keyup', (e) => {
                if (e.key === "Escape") {
                    setShowSearch(false)
                }
            })
        }
        return ()=>{subscribed = false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const onSearchFocus = () => {
        let subscribed = true;
        if (subscribed) {
            setIsSearching(true)
        }
        return ()=>{subscribed=false}
    }


    const onSearchBlur = () => {
        let subscribed = true;
        if (subscribed) {
            setIsSearching(searchVal)
        }
        return ()=>{subscribed=false}
    }

    const onSearchChange = ( value ) => {
        setSearchVal(value)
        if(value) {
            setIsSearching(true)
            search(value)
                .then(result => {
                    console.log(result)
                    setList(result.data)
                })
        } else setIsSearching(false)
    }

    const clearSearchInput = (e) => {
        let subscribed = true;
        e.preventDefault()
        searchInputRef.current.focus()
        if (subscribed) {
            setSearchVal("")
            setIsSearching(false)
            // Event(TrackingEvents.categories.SEARCH, TrackingEvents.actions.SEARCH_INPUT_CLEAR, TrackingEvents.labels.SEARCH_INPUT_CLEAR)
        }
        return ()=>{subscribed=false}
    }

    return (
        <>
            <div className="" style={{
                position:'absolute',
                top:50,
                right:50,
                width:'24px',
                height:'24px',
                zIndex:111}}>
              <div style={{cursor:'pointer'}} className="ml-auto" onClick={()=>{
                setShowSearch(false)
              }}>
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className=""><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
              </div>
            </div>
            <div className="w-100">
                <Row className="width_ctrl justify-content-center" nogutters="true">
                    <div className="col-12 col-sm-8 col-md-6">

                        <form style={{
                            transform:'translateY(-50%)',
                            position:'relative',
                            top:'50%'
                        }} className="search__form form-inline d-flex justify-content-center md-form form-sm">
                            <input
                                onFocus={onSearchFocus}
                                onBlur={onSearchBlur}
                                value={searchVal}
                                onChange={(e) => {
                                    e.preventDefault()
                                    onSearchChange(e.target.value)
                                }}
                                ref={searchInputRef}
                                tabIndex="-1"
                                className="search__input form-control form-control-sm"
                                type="text"
                                placeholder="Search FYC by Title, Cast, or Keyword..."
                            />
                            { (isSearching) ? (
                                <>
                                <button type="button" onClick={ (e) => clearSearchInput(e)} className="d-none button button__clear ">Clear Search</button>
                                <button type="submit" onClick={ (e) => e.preventDefault()} className="d-none">Search</button>
                                </>
                            ) : (
                                ""
                            )}
                            <i className="icon " aria-hidden="true"><img src={iconSearch} className="icon__image" alt="Netflix" /></i>
                        </form>
                    </div>
                </Row>


                { (isSearching && list && Object.keys(list).length > 0) ? (

                    <div className="searchresults__container">
                        <Row className="width_ctrl justify-content-center" nogutters="true">
                                {
                                    list.map((r, i) => {
                                        if (r.genres && r.genres.length) {
                                            let category_slug = r.genres[0].slug
                                            if (category_slug.includes('documentary')) {
                                                category_slug = 'documentary-series-or-special'
                                            }
                                            if (category_slug.includes('variety')) {
                                                category_slug = 'variety-programs'
                                            }
                                            if (category_slug.includes('reality')) {
                                                category_slug = 'reality-series'
                                            }
                                            return (
                                                <a key={ i } className="searchresult col-6 col-md-2 p-3 m-2" href={ '/'+category_slug+'/'+r.slug }>{ r.title }</a>
                                            )
                                        } else {
                                            return null
                                        }

                                  })
                                }
                        </Row>
                    </div>
                ) : (
                    ""
                )}

            </div>
        </>
    )
}

export default Search
