import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import categoryBackground from '../../Common/_assets/img/bg/bg-streams4.png'
import TitleList from './TitleList/TitleList'
import SubNav from '../Subnav/Subnav'
import Footer from '../Footer/Footer'
import {useParams} from "react-router-dom";
import {getShowGenrePageData} from "../../utils/api/helpers/ShowGenres";

const Category = () => {

    const { categorySlug }  = useParams()

    const [shows,setShows] = useState(null),
          [featuredShows,setFeaturedShows] = useState(null)

    // const [ categories, setCategories ]  = useState(null)

    useEffect( () => {
        getShowGenrePageData(categorySlug)
            .then(result => {
                if (result.data && result.status === 200) {
                    setFeaturedShows(result.data.featuredShows)
                    setShows(result.data.shows)
                }
                // setCategories(result.data)
            }).catch(() => {});
    }, [categorySlug])

return (

    <>

        <Helmet>
            <title>Netflix FYC - Series</title>
        </Helmet>

        <div className="mt-5 mt-md-0 subnav__container--absolute d-flex align-items-center">
            <SubNav />
        </div>

        <div className="mt-5 mt-md-0">

            {
                categorySlug === 'documentary-series-or-special' ?
                    <>
                      <TitleList shows={featuredShows} featured={true} category_slug={categorySlug}  />
                      <TitleList shows={shows} featured={false} category_slug={'documentary-series'}  />
                      <TitleList shows={shows} featured={false} category_slug={'documentary-exceptional-merit'}  />
                    </>
                : categorySlug === 'reality-series' ?
                    <>
                      <TitleList shows={featuredShows} featured={true} category_slug={categorySlug}  />
                        {/*<TitleList shows={shows} featured={false} category_slug={'structured-reality'}/>*/}
                      <TitleList shows={shows} featured={false} category_slug={'unstructured-reality'}  />
                      <TitleList shows={shows} featured={false} category_slug={'competition-program'}  />
                    </>
                : categorySlug === 'variety-programs' ?
                    <>
                      <TitleList shows={featuredShows} featured={true} category_slug={categorySlug}  />
                        <TitleList shows={shows} featured={false} category_slug={'variety-special-pre-recorded'}  />
                      <TitleList shows={shows} featured={false} category_slug={'variety-special-live'}  />
                        <TitleList shows={shows} featured={false} category_slug={'variety-sketch-series'}  />
                    </>
                :
                    <>
                        <TitleList shows={featuredShows} featured={true} category_slug={categorySlug}  />
                        <TitleList shows={shows} featured={false} category_slug={categorySlug}  />
                    </>
            }

        </div>

        <div className="main__background" style={{
            backgroundImage:"url("+categoryBackground+")",
            opacity:'.3'
        }}/>

        <Footer />

    </>

    )
}

export default Category
