import React, {useLayoutEffect, useState} from 'react'
import Footer from '../Footer/Footer'

const ContentPage = ({thisPageName}) => {

    const [pageName, setPageName] = useState('')

    useLayoutEffect( () => {
      let subscribed = true;

      if (subscribed) {

        if (thisPageName === "privacy") {
          setPageName('Privacy Statement')
        } else if (thisPageName === "faq") {
          setPageName('Frequently Asked Questions')
        } else if (thisPageName === "maintenance") {
          setPageName('Maintenance')
        } else {

        }
        return () => {subscribed=false}
      }

    }, [ thisPageName ])

    if (thisPageName === "privacy") {

      return (

      <>

        <div className="page__container row no-gutters width_ctrl my-5">

        <div className="page__content">

        <h3>{pageName}</h3>

        <p>Welcome to the Netflix “For Your Consideration” Experience (“Experience”)! This Privacy and Terms document (“Terms”) explains our privacy practices and terms for this Experience.</p>
        <p>You must be at least 18 years of age to interact with this Experience. This Experience is brought to you by Netflix, Inc.  For questions about our privacy practices, this Experience, or these Terms, please contact us by email at <a href="mailto:Experience@Netflix.Com">Experience@Netflix.Com</a>. Please include the name of the Experience if you contact us.</p>
        <h3>Collection and Use of Information</h3>
        <p>Some experiences on the Experience permit or require you to register for an account. We and our service providers will use the information and content you provide through registration to administer those Experiences. This type of information may include your name, e-mail address, phone number, professional affiliations, and requested Experience attendance. Registrants may be required to show proof of affiliation upon request.</p>
        <p>This Experience may permit you to sign up to receive our email newsletter. You can unsubscribe from such emails at any time by clicking on the “unsubscribe” link in the footer, or by following such other instructions as may be provided to unsubscribe.</p>
        <p>When you interact with us, certain information might be collected automatically. Examples of information include: the type of computer operating system, device and software characteristics (such as type and configuration), referral URLs, IP address (which may tell us your general location), statistics on page views or interactions with activities, and browser and standard web server log information. This information is collected using technologies such as cookies, pixel tags, and web beacons. We use this information for the support of internal operations, such as to conduct research and analysis to address the performance of our Experience, and to generate aggregated or de-identified reporting for our use.</p>
        <p>This Experience might use cookies to support the performance of our site and to remember choices you have made, such as preferred language. You can modify your browser settings to control whether your computer or other device accepts or declines cookies. If you choose to decline cookies you may not be able to use certain interactive features of this Experience or certain of its Experiences. You can delete cookies from your browser; however, that means that any settings or preferences managed by those cookies will also be deleted and you may need to recreate them. Depending on your mobile device, you may not be able to control tracking technologies through settings. The emails we send might include a web beacon or similar technology that lets us know if you received or opened the email and whether you clicked on any of the links in the email.</p>
        <p>This Experience might use Google Analytics, a web analytics service offered by Google. Google Analytics assists us in gathering analytics and statistical data in connection with the Experience. On our behalf Google processes this information to analyze the usage of the Experience, create reports on the Experience activities, and provide other services related to Experience and internet usage for us. If you have any questions or concerns with regard to Google Analytics’ privacy practices, you can review their privacy policy at <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>.</p>
        <p>The Experience might give you the option to share information by email, social or other sharing applications, using the clients and applications on your smart device. Social plugins (including those offered by Facebook, Twitter, Instagram, and Pinterest) allow you to share information on those platforms. Social plugins and social applications are operated by the social network themselves and are subject to their terms of use and privacy policies.</p>
        <h3>Disclosure of Information</h3>
        <p>We may disclose your information for certain purposes and to third parties, as described below:</p>
        <ul>
          <li>The Netflix family of companies: We might share your information among the Netflix family of companies (https://help.netflix.com/support/2101) as needed for data processing and storage, providing customer support, content development, and for other purposes described in the Use of Information Section of this document.</li>
          <li>We might use other companies, agents or contractors (“Experience Providers”) to perform services on our behalf or to help us to provide this Experience to you. For example, we may use Experience Providers to provide infrastructure and IT services (like hosting the Experience). We do not authorize Experience Providers to use information except in connection with providing their services, subject to the following safety issues. We and our Experience Providers may disclose and otherwise use information where we or they reasonably believe such disclosure is needed to (a) satisfy any applicable law, regulation, legal process, or governmental request, (b) enforce these Terms, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address illegal or suspected illegal activities, security or technical issues, or (d) protect against harm to the rights, property or safety of Netflix, our content partners, users or the public, as required or permitted by law.</li>
          <li>We use reasonable administrative, logical, physical and managerial measures to safeguard your information against loss, theft and unauthorized access, use and modification. We may retain information as required or permitted by applicable laws and regulations, including to fulfill the purposes described in these Terms.</li>
        </ul>
        <p>If, in the course of sharing information, we transfer personal information to countries outside of the European Economic Area and other regions with comprehensive data protection laws, we will ensure that the information is transferred in accordance with these Terms and as permitted by the applicable laws on data protection.</p>
        <h3>Your Information and Rights</h3>
        <p>You can request access to your personal information or correct or update out-of-date or inaccurate personal information we hold about you. You can object to processing of your personal information, ask us to restrict processing of your personal information, or request portability of your personal information. If we have collected and processed your personal information with your consent, you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
        <p>To make requests, or if you have any other question regarding our privacy practices, please contact our Data Protection Officer/Privacy Office at <a href="mailto:Experience@Netflix.Com">Experience@Netflix.Com</a>. We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</p>
        <p>You have the right to complain to a data protection authority about our collection and use of your personal information. Our main establishment within the European Union is in the Netherlands.</p>
        <h3>Intended use of the Experience</h3>
        <p>This Experience and any related content or activities are for your personal and non-commercial use only. During your use of the Experience, we grant you a limited, non-exclusive, non-transferable, license to access the Experience content and activities. Except for the foregoing limited license, no right, title or interest shall be transferred to you. You agree not to use the Experience for public performances. You are responsible for all Internet access charges.</p>
        <p>You agree not to archive, download (other than through caching necessary for personal use), reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale, or use content and information contained on or obtained from or through the Experience without express written permission from Netflix and its licensors. You also agree not to: circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the Experience; use any robot, spider, scraper or other automated means to access the Experience; decompile, reverse engineer or disassemble any software or other products or processes accessible through the Experience; insert any code or product or manipulate the content of the Experience in any way; or, use any data mining, data gathering or extraction method. In addition, you agree not to upload, post, email or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the Experience, including any software viruses or any other computer code, files or programs.</p>
        <p>NEITHER NETFLIX NOR ITS AFFILIATED ENTITIES, NOR ANY OF ITS AGENCIES, NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE EXPERIENCE, IS LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT, OR PUNITIVE DAMAGES OR LOSSES ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO, OR USE OF, THE EXPERIENCE, EVEN IF ADVISED IN ADVANCE OF SUCH DAMAGES OR LOSSES, TO THE EXTENT PERMITTED BY APPLICABLE LAW.</p>
        <p>Netflix may suspend or terminate your account if you fail to comply with these Terms.</p>
        <p>The Experience, including all content provided on the Experience, is protected by copyright, trade secret or other intellectual property laws and treaties. Netflix is a registered trademark of Netflix, Inc. If you believe your work has been reproduced or distributed in a way that constitutes a copyright infringement or are aware of any infringing material available through the Experience, please notify us by completing the Copyright Infringement Claims form (<a href="https://www.netflix.com/copyrights" target="_blank" rel="noopener noreferrer">www.netflix.com/copyrights</a>).</p>
        <p>By using, visiting, browsing, or uploading any information to the Experience, you accept and agree to these Terms. If you do not accept these Terms and/or any updates to these Terms, please do not use this Experience.</p>

        </div>
        </div>
        <div className="main__background"></div>
        <Footer />
      </>

      )

    } else if (thisPageName === "faq") {

      return (

      <>

        <div className="page__container row no-gutters width_ctrl my-5">
        <div className="page__content">

          <h3>Need website RSVP help?</h3>

          <p className="text-center"><span >Please contact us at </span><a href="mailto:awards@netflix.com"><span >awards@netflix.com</span></a><span > with “RSVP HELP” in the subject line.</span></p>

          <h3 >Have feedback regarding an event?</h3>

          <p className="text-center"><span >Please contact us at </span><a href="mailto:awards@netflix.com"><span >awards@netflix.com</span></a><span > with “EVENT FEEDBACK” in the subject line.</span></p>

          <h3 >Need to update your email or mailing address for invites and screeners? </h3>

          <p className="text-center"><span >Email us at </span><a href="mailto:awards@netflix.com"><span >awards@netflix.com</span></a><span > with “SCREENER HELP” and your guild affiliation in the subject line.</span></p>

          <h3 >Looking for our screener site?</h3>

          <p className="text-center">
          <strong>Go to <a href="https://fyc.netflix.com/" target="_blank" rel="noopener noreferrer">fyc.netflix.com</a></strong>
          </p>

          <h3 >Need a viewing code?</h3>

          <p className="text-center"><span >Email us at </span><a href="mailto:awards@netflix.com"><span >awards@netflix.com</span></a><span > with “SCREENER HELP” and your guild affiliation in the subject line.</span></p>

          <h3 >Missing screeners?</h3>

          <p className="text-center"><span >Email us at </span><a href="mailto:awards@netflix.com"><span >awards@netflix.com</span></a><span > with “SCREENER HELP” and your guild affiliation in the subject line.</span></p>

          <h3 >I have additional questions…</h3>

          <p className="text-center"><span >Please contact us at </span><a href="mailto:awards@netflix.com"><span >awards@netflix.com</span></a><span >.</span></p>

          <h3 >Need ADA assistance onsite?</h3>

          <p className="text-center"><span >Please contact us at </span><a href="mailto:awards@netflix.com"><span >awards@netflix.com</span></a><span > with “ADA ACCOMMODATIONS REQUEST” in the subject line.</span></p>
          <h3 >Some additional key information:</h3>

          <p className="text-center"><span >Only Television Academy Members with valid membership cards and their guests will be admitted to the events noted as “ATAS Official Event”. </span></p>
          <p className="text-center"><span >All events are first come, first served. There will be a wait in line upon entrance. </span></p>
          <p className="text-center"><span >Invites are non-transferable and all cancelations are final.</span></p>
          <p className="text-center"><span >Parking for each event hosted at FYSEE is complimentary.</span></p>

        </div>
        </div>
        <div className="main__background"></div>
        <Footer />
      </>

      )

    } else if (thisPageName === "maintenance") {

      return (

          <>
            <div className="page__container row no-gutters  width_ctrl my-5">
              <div className="page__content mt-5">
                <h3>Temporarily Down for Maintenance</h3>
              </div>
            </div>
            </>
      )
    } else {
      return ("");
    }
}

export default ContentPage
