import React from 'react'
import TitlePanel from './TitlePanel'
// import category from "../Category";
// import TitlePanelCustom from './TitlePanelCustom'
// import Show from "../../../Common/lib/Show/Show"


const TitleList = ({shows, category_slug, featured}) => {

    if (shows && Object.keys(shows).length>0) {
      return (
      <>
        {
            (!featured && (category_slug==='documentary-series'
                            || category_slug==='documentary-special'
                            || category_slug==='documentary-exceptional-merit'
                            || category_slug==='competition-program'
                            || category_slug==='structured-reality'
                            || category_slug==='unstructured-reality'
                            || category_slug==='hosted-nonfiction-series-or-special'
                            || category_slug==='variety-special-pre-recorded'
                            || category_slug==='variety-programs')) ?
                <h1 className="px-5 text-center mb-5 font-weight-bold genre-section-title-custom">
                    { category_slug==='childrens-animated-programs' ? "Children's Programs" : category_slug.replace(/-/g, ' ') }
                </h1>
            : null
        }

          <div className="genre__container width_ctrl">
            <div id="genre__grid" className={ "mb-5 genre__tier genre__tier--one d-flex flex-wrap justify-content-center "}>
                { shows ?
                    shows.map( (s, i) => {
                        let sGenres = '['+s.genres[0]+']'
                        sGenres = JSON.parse(sGenres)
                        let genreMatch = sGenres.some(e => e.slug === category_slug)
                        // console.log(category_slug, sGenres, genreMatch)
                        if (genreMatch) {
                            return (
                                <TitlePanel key={ i } categories={s.genres} category_slug={category_slug} featured={featured} show_featured={s.featured} show_slug={ s.slug } show_title={ s.title } key_art={ s.keyArt } />
                            )
                        } else {
                            return null
                        }

                  })
                : null
                }
            </div>
          </div>
      </>
      )
    } else {
      return ""
    }
}

export default TitleList
