import React, {useEffect, useState} from 'react'
// import React, { useLayoutEffect, useState } from 'react'
// import Event from "../../Common/lib/Event/Event";
import EventExcerpt from './EventExcerpt'

import {listEvents} from "../../utils/api/helpers/event";

const EventList = () => {

    const [eventList, setEventList] = useState(null)

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            listEvents()
                .then(result => {
                    console.log("events", result.data)
                    if (result.data) {
                        setEventList(result.data)
                    }
                })
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

    <>
      <div className="width_ctrl row no-gutters justify-content-center">
        <div className="col-12 events__grid mb-5">


            <div className="events__grid--inner row justify-content-center">
              <h1 className="events__header col-12 text-center" >EVENTS</h1>

                { eventList && Object.keys(eventList).length ?
                    eventList.map((o,i)=>{
                        return <EventExcerpt key={i} event_object={ o } />
                    })
                  :
                  <div className="text-center ">Please check back soon for more events to come...</div>
                }

                <div className="d-none text-center" style={{textTransform:'uppercase',fontWeight:'300',letterSpacing:'.35rem'}}>
                    Stay tuned for more events...
                </div>

            </div>

        </div>
      </div>
      <div className="main__background" />
    </>

    )
}

export default EventList
