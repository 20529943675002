import React from 'react'
import netflixLogo from '../../Common/_assets/img/logo/netflix-logo-ribbon.png'

const Header = ({setShowSearch}) => {

    return (
      <header className="header__container">

        <div className="header__sticky d-flex">
          <div className="width_ctrl d-flex align-items-center">

            <div className=" header__logo-wrapper ms-0 ms-md-3 me-2 me-md-3">
              <a href="/"><img src={netflixLogo} className="header__logo" alt="Netflix" /></a>
            </div>

            <div className=" header__title d-flex flex-row justify-content-start">
              <a href="/">
                <span className="header__title--main">Series</span>
                <span className="header__title--tagline d-none d-md-block">For Your Consideration</span>
                <span className="header__title--tagline d-block d-md-none">FYC</span>
              </a>
            </div>

            <div className="d-flex header__buttons-container flex-row align-items-center justify-content-end ms-auto">
              <div className="d-flex header__buttons header__buttons--desktop justify-content-end ms-auto">
                <button className=" p-0 " onClick={()=>{
                  setShowSearch(true)
                }}><span className="button__text">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                  </span>
                </button>

                <a target="_blank" rel="noopener noreferrer" href="https://fyseetv.netflixawards.com" className="ms-2 ms-md-5 d-flex align-items-center"><button className="p-0 "><span className="button__text font-weight-bold">
                  <span style={{color:"#ee2b39",fontWeight:'300'}}><strong>FY</strong>SEE</span> TV
                  </span>
                </button></a>

                <a href="/events" className="d-flex ms-2 ms-md-5 align-items-center"><button className="p-0 "><span className="button__text font-weight-bold">
                  EVENTS
                  </span>
                </button></a>

              </div>
            </div>

          </div>
        </div>
      </header>
    )
}

export default Header
