import React, { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
// import { Button } from 'react-bootstrap'

import Moment from 'react-moment'
import CompetitionsAwards from '../CompetitionsAwards/CompetitionsAwards'

// import { Scrollbars } from 'react-custom-scrollbars'

import socialIconInstagram from '../../../Common/_assets/img/icon/iconsimple-ig.png'
import socialIconTwitter from '../../../Common/_assets/img/icon/iconsimple-x.png'
import socialIconFacebook from '../../../Common/_assets/img/icon/iconsimple-fb.png'

const Sidebar = ({award_count,
                     titleName,
                  titleId,
                  titleSlug,
                  trailerId,
                  // trailerTimeout,
                  trailerLoaded,
                  setTrailerLoaded,
                  stopVideo,
                  stopMP4Video,
                  playVideo,
                  socialFB,
                  socialTW,
                  socialIG,
                  sagIneligible,
                  titleTTImage,
                  reviewQuote,
                  reviewCredit,
                  viewingLink,
                  availableOn,
                  hasAwards,
                  competitions,
                  hasImages,
                  hasAudio,
                  hasEvents,
                  hasVideos,
                  hasMediaRead,
                  hasNominated,
                  setHasNominated,
                  hasLinks
                }) => {

    const match = useParams()

    useLayoutEffect( () => {
      let subscribed = true;
      if (subscribed) {
        Moment.globalLocale = 'en-us'
      }
      return ()=>{subscribed=false}
    }, [award_count])

  return (

    <div className="col-12 col-md-4 sidebar__container--titlepage ">
        <div className="sidebar__container--inner d-flex flex-column justify-content-center">

            <div
                className={"titlepage__logo--container px-0 px-md-3 d-flex flex-column " + ((award_count) ? '' : 'flex-fill justify-content-center align-items-center')}>

                {(titleTTImage !== "") ? (
                    <a href={'/' + match.categorySlug + '/' + match.titleSlug}
                       className="titletreatment-container d-flex justify-content-center align-items-center mb-0"><img
                        src={titleTTImage} style={{maxHeight: '250px'}} className="titletreatment my-3"
                        alt={titleName}/></a>
                ) : (
                    ""
                )}


                {
                    reviewQuote && reviewCredit ?

                        <div className="d-block text-center pb-3 "
                             style={{letterSpacing: '0rem', lineHeight: '1.35em', fontSize: '1.15rem'}}>
                            "{reviewQuote}"
                            <div className="text-small font-weight-bold">~ {reviewCredit}</div>
                        </div>

                        : ""
                }

                {
                    viewingLink ?
                        <div
                            className="d-flex titlepage__button--container pb-3 justify-content-center align-items-center">
                            <a href={"https://screeners.netflixawards.com/title/" + viewingLink}>
                                <button className="button__box">Watch Now</button>
                            </a>
                        </div>
                        :
                        availableOn ?

                            <div className="text-center py-3">
                                Available&nbsp; <Moment format="MMMM D, YYYY ">{availableOn}</Moment>
                            </div>

                            : ""
                }


                {(socialFB || socialIG || socialTW) ? (
                    <div className="titlepage__social--container pb-3  justify-content-center align-items-center ">
                        <div className="w-100 d-flex justify-content-center align-items-center">

                            {(socialTW) ? (
                                <div className="p-2"><a href={socialTW} target="_blank" rel="noopener noreferrer"><img
                                    className="social-icon mx-1" src={socialIconTwitter} alt="Netflix"/></a></div>
                            ) : ("")}

                            {(socialIG) ? (
                                <div className="p-2"><a href={socialIG} target="_blank" rel="noopener noreferrer"><img
                                    className="social-icon mx-1" src={socialIconInstagram} alt="Netflix"/></a></div>
                            ) : ("")}

                            {(socialFB) ? (
                                <div className="p-2"><a href={socialFB} target="_blank" rel="noopener noreferrer"><img
                                    className="mx-1 social-icon" src={socialIconFacebook} alt="Netflix"/></a></div>
                            ) : ("")}


                        </div>
                    </div>
                ) : ("")}


            </div>

            <div className="d-block d-md-none mt-4 mb-5 text-center">
                <div className="font-weight-light text-uppercase text-center mx-auto d-inline-block" style={{
                    // background:"rgba(255,255,255,.15)",
                    borderRadius: "7px",
                    fontSize: '.7rem',
                    letterSpacing: '.3rem'
                }}>
                    <div className={'mb-3 text-red'}
                         style={{fontSize: '2.5rem', fontWeight: '300', letterSpacing: '0rem'}}>{award_count}</div>
                    <div>Emmy&reg; Nomination{award_count > 1 ? 's' : ''}</div>
                </div>
            </div>


            <div className={" sidebar__scrollcontainer--outer flex-fill " + (hasAwards ? "d-block" : "d-none")}>
                <div className="scroll-container sidebar__scrollcontainer--inner">

                    <div className="sidebar__scrollcontainer--content">

                        {
                            hasAwards && competitions ?
                                <div className="d-none d-md-flex flex-md-fill flex-column">
                                    <CompetitionsAwards show_id={titleId} slug={titleSlug} titleName={titleName}
                                                        award_count={award_count} competitions={competitions}/>
                                </div>
                                : ""
                        }

                    </div>

                </div>
            </div>

            {
                hasAwards ?
                    <div className="text-center text-small text-uppercase my-3 d-flex justify-content-center"
                         style={{fontSize: '.6rem', letterSpacing: '.15rem', color: '#999'}}>
                        <button className="button__outline px-3" style={{fontSize: '.6rem', letterSpacing: '.3em'}}>
                            <a href={'/' + match.categorySlug + '/' + match.titleSlug + '/awards'}
                               style={{letterSpacing: '.05rem'}} className="">View {award_count > 1 ? 'All Nominations' : 'The Nomination'}</a>
                        </button>

                    </div>
                    : ""
            }

            <div className="d-flex sidebar__container--explore-toggle pb-4 w-100">
                <div
                    className="sidebar__container--explore-toggle-link d-flex justify-content-center align-items-center w-100">

                    {

                        trailerId && !match.mediaSection ?

                            <div className="sidebar__container--explore-icon " onClick={() => {
                                if (trailerLoaded) {
                                    if (titleSlug !== 'somebody-feed-phil') {
                                        stopVideo()
                                    } else {
                                        stopMP4Video()
                                    }
                                    setTrailerLoaded(false)
                                } else {
                                    setTrailerLoaded(true)
                                    playVideo()
                                }
                            }}>
                                <div className="">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                         strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                         className="css-i6dzq1">
                                        <rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect>
                                        <line x1="7" y1="2" x2="7" y2="22"></line>
                                        <line x1="17" y1="2" x2="17" y2="22"></line>
                                        <line x1="2" y1="12" x2="22" y2="12"></line>
                                        <line x1="2" y1="7" x2="7" y2="7"></line>
                                        <line x1="2" y1="17" x2="7" y2="17"></line>
                                        <line x1="17" y1="17" x2="22" y2="17"></line>
                                        <line x1="17" y1="7" x2="22" y2="7"></line>
                                    </svg>
                                </div>
                                <div className="sidebar__container--explore-icon-tip arrowbox__grey">
                                    {trailerLoaded ? "Hide Trailer" : "Show Trailer"}
                                </div>
                                <div className="sidebar__container--explore-icon-label">
                                    Trailer
                                </div>
                            </div>

                            : trailerId && match.mediaSection ?

                                <a className="sidebar__container--explore-icon "
                                   href={"/" + match.categorySlug + "/" + titleSlug}>
                                    <div className="">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                             strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                             className="css-i6dzq1">
                                            <rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect>
                                            <line x1="7" y1="2" x2="7" y2="22"></line>
                                            <line x1="17" y1="2" x2="17" y2="22"></line>
                                            <line x1="2" y1="12" x2="22" y2="12"></line>
                                            <line x1="2" y1="7" x2="7" y2="7"></line>
                                            <line x1="2" y1="17" x2="7" y2="17"></line>
                                            <line x1="17" y1="17" x2="22" y2="17"></line>
                                            <line x1="17" y1="7" x2="22" y2="7"></line>
                                        </svg>
                                    </div>
                                    <div className="sidebar__container--explore-icon-label">
                                        Trailer
                                    </div>
                                </a>

                                : ""

                    }

                    {
                        hasVideos ?

                            <a className=" sidebar__container--explore-icon"
                               href={"/" + match.categorySlug + "/" + titleSlug + "/videos"} onClick={() => {
                                // window.clearTimeout(trailerTimeout)
                                setTrailerLoaded(false)
                            }}>
                                <div className="">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                         strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                         className="css-i6dzq1">
                                        <polygon points="23 7 16 12 23 17 23 7"></polygon>
                                        <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                                    </svg>
                                </div>
                                <div className="sidebar__container--explore-icon-tip arrowbox__grey">
                                    Videos
                                </div>
                                <div className="sidebar__container--explore-icon-label">
                                    Videos
                                </div>
                            </a>

                            : ""
                    }

                    {/*
                hasImages?

                  <a className=" sidebar__container--explore-icon" href={"/"+match.categorySlug+"/"+titleSlug+"/images"} onClick={()=>{
                      setTrailerLoaded(false)
                  }}>
                    <div className=""><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg></div>
                    <div className="sidebar__container--explore-icon-label">
                      Images
                    </div>
                  </a>

                :""
              */}


                    {/*
                hasAudio ?

                  <a className=" sidebar__container--explore-icon" href={"/"+match.categorySlug+"/"+titleSlug+"/audio"} onClick={()=>{
                    setTrailerLoaded(false)
                  }}>
                    <div className=""><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M3 18v-6a9 9 0 0 1 18 0v6"></path><path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path></svg></div>
                    <div className="sidebar__container--explore-icon-label">
                      Listen
                    </div>
                  </a>


                :""
              */}


                    {
                        hasMediaRead ?

                            <a className=" sidebar__container--explore-icon"
                               href={"/" + match.categorySlug + "/" + titleSlug + "/read"} onClick={() => {
                                // window.clearTimeout(trailerTimeout)
                                setTrailerLoaded(false)
                            }}>
                                <div className="">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                         strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                         className="css-i6dzq1">
                                        <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
                                        <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
                                    </svg>
                                </div>
                                <div className="sidebar__container--explore-icon-label">
                                    Scripts
                                </div>
                            </a>

                            : ""
                    }


                    {
                        hasEvents ?

                            <a className=" sidebar__container--explore-icon"
                               href={"/" + match.categorySlug + "/" + titleSlug + "/events"} onClick={() => {
                                // window.clearTimeout(trailerTimeout)
                                setTrailerLoaded(false)
                            }}>
                                <div className="">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                         strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                         className="css-i6dzq1">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                </div>
                                <div className="sidebar__container--explore-icon-label">
                                    Events
                                </div>
                            </a>

                            : ""
                    }

                    {
                        titleSlug === 'the-crown' ?
                            <a className=" sidebar__container--explore-icon"
                               href={"https://www.thecrownretrospective.com/"} target={"_blank"}
                               rel={"noopener noreferrer"}>
                                <div className="">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                         strokeWidth="2"
                                         fill="none" strokeLinecap="round" strokeLinejoin="round"
                                         className="css-i6dzq1">
                                        <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                                        <line x1="8" y1="21" x2="16" y2="21"></line>
                                        <line x1="12" y1="17" x2="12" y2="21"></line>
                                    </svg>
                                </div>
                                <div className="sidebar__container--explore-icon-label">
                                    Website
                                </div>
                            </a>
                            : ""
                    }

                    {/*
                  hasLinks ?
                      <a className=" sidebar__container--explore-icon" href={"/"+match.categorySlug+"/"+titleSlug+"/press"} onClick={()=>{
                        // window.clearTimeout(trailerTimeout)
                        setTrailerLoaded(false)
                      }}>
                        <div className=""><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1">

                        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>

                        </svg></div>
                        <div className="sidebar__container--explore-icon-label">
                          Press
                        </div>
                      </a>
                  : null
              */}


                </div>
            </div>

            {/*
          sagIneligible ?
            <div className="sag-ineligible py-2 justify-content-center align-items-center text-center text-small">
              <div className="">This content is not eligible for SAG Awards® consideration.</div>
            </div>
          :""
        */}

        </div>

    </div>


  )

}

export default Sidebar
