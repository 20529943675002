import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import TitleMediaGridItem from '../TitleMediaGridItem'

const TitleMediaVideo = ({taglist_videos, fyseeVideoList,mediaSection}) => {

  const [ loaded, setLoaded ] = useState( false ),
        [ videos_acting, setVideosActing ] = useState( null ),
        [ videos_directing, setVideosDirecting ] = useState( null ),
        [ videos_writing, setVideosWriting ] = useState( null ),
        [ videos_crafts, setVideosCrafts ] = useState( null )

  const {categorySlug,titleSlug} = useParams()

    useEffect( () => {
        let subscribed=true
        if (subscribed && ((taglist_videos && Object.keys(taglist_videos).length>0)) && !loaded) {
            setLoaded(true)

            const v_acting = taglist_videos.filter(o=>{
                return o.all_tags ? o.all_tags.split(',').includes('acting') : null
            })
            setVideosActing(v_acting)

            const v_directing = taglist_videos.filter(o=>{
                return o.all_tags.split(',').includes('directing')
            })
            setVideosDirecting(v_directing)

            const v_writing = taglist_videos.filter(o=>{
                return o.all_tags.split(',').includes('writing')
            })
            setVideosWriting(v_writing)

            const v_crafts = taglist_videos.filter(o=>{
                return o.all_tags.split(',').includes('crafts')
            })
            setVideosCrafts(v_crafts)


        }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ taglist_videos ])

    useEffect( () => {
        let subscribed=true
        if (subscribed && (fyseeVideoList && Object.keys(fyseeVideoList).length>0 && !loaded) ) {
            setLoaded(true)
        }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ fyseeVideoList ])

    return (
      <>
          <div className="pe-0 pe-md-5 mb-5 position-relative w-100  scroll-container" style={{maxHeight:'75vh',height:'75vh',overflow:'scroll'}}>
            <div className="">
              <div className="p-3">
                {
                    !loaded ? <p>Loading...</p>
                        : (
                            <div className="">


                                {(videos_acting && Object.keys(videos_acting).length>0) ?
                                    <>
                                    <h2 className="text-uppercase text-center my-5 " style={{color:'#fff',fontWeight:300,lineHeight:'1.2em',letterSpacing:'.4rem'}}>Acting</h2>
                                    <div className="row justify-content-center">
                                    {videos_acting.map( (m, i) =>
                                        <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                                    </div>
                                    </>
                                :null
                                }
                                {(videos_directing && Object.keys(videos_directing).length>0) ?
                                    <>
                                    <h2 className="text-uppercase text-center my-5 " style={{color:'#fff',fontWeight:300,lineHeight:'1.2em',letterSpacing:'.4rem'}}>Directing</h2>
                                    <div className="row justify-content-center">
                                    {videos_directing.map( (m, i) =>
                                        <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                                    </div>
                                    </>
                                :null
                                }
                                {(videos_writing && Object.keys(videos_writing).length>0) ?
                                    <>
                                    <h2 className="text-uppercase text-center my-5" style={{color:'#fff',fontWeight:300,lineHeight:'1.2em',letterSpacing:'.4rem'}}>Writing</h2>
                                    <div className="row justify-content-center">
                                    {videos_writing.map( (m, i) =>
                                        <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                                    </div>
                                    </>
                                :null
                                }
                                {(videos_crafts && Object.keys(videos_crafts).length>0) ?
                                    <>
                                    <h2 className="text-uppercase text-center my-5 " style={{color:'#fff',fontWeight:300,lineHeight:'1.2em',letterSpacing:'.4rem'}}>Crafts</h2>
                                    <div className="row justify-content-center">
                                    {videos_crafts.map( (m, i) =>
                                        <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                                    </div>
                                    </>
                                :null
                                }

                                { ((taglist_videos && (!videos_directing || Object.keys(videos_directing).length<=0) && (!videos_writing || Object.keys(videos_writing).length<=0) && (!videos_acting || Object.keys(videos_acting).length<=0) && (!videos_crafts || Object.keys(videos_crafts).length<=0)) || fyseeVideoList) ?
                                        <h2 className="text-uppercase text-center my-5" style={{color:'#fff',fontWeight:300,lineHeight:'1.2em',letterSpacing:'.4rem'}}>VIDEOS</h2>
                                    :null
                                }

                                <div className="row justify-content-center">

                                    {(taglist_videos && (!videos_directing || Object.keys(videos_directing).length<=0) && (!videos_writing || Object.keys(videos_writing).length<=0) && (!videos_acting || Object.keys(videos_acting).length<=0) && (!videos_crafts || Object.keys(videos_crafts).length<=0)) ?
                                        taglist_videos.map( (m, i) =>
                                            <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )
                                    :null
                                    }

                                    {fyseeVideoList ?
                                        fyseeVideoList.map( (m, i) =>
                                            <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )
                                    :null
                                    }

                                </div>

                            </div>
                        )
                }

              </div>
            </div>
          </div>
      </>
    )
}

export default TitleMediaVideo
