import React, {useEffect, useState} from 'react'
import TitleMediaGridItem from './TitleMediaGridItem'

const TitleMediaGrid = ({titleSlug, categorySlug, mediaList, mediaSection}) => {

    const [ images_hairandmakeup, set_images_hairandmakeup ] = useState( null ),
            [ images_productiondesign, set_images_productiondesign ] = useState( null ),
            [ images_vfx, set_images_vfx ] = useState( null ),
            [ images_costumes, set_images_costumes ] = useState( null ),
            [ images_other, set_images_other ] = useState( null )

    useEffect( () => {
        let subscribed=true
        if (subscribed && ((mediaList && Object.keys(mediaList).length>0))) {

            if (mediaSection==='images') {
                const i_hairandmakeup = mediaList.filter(o=>{
                    return o.all_tags ? o.all_tags.split(',').includes('hair-and-makeup') : null
                })
                set_images_hairandmakeup(i_hairandmakeup)
                const i_productiondesign = mediaList.filter(o=>{
                    return o.all_tags ? o.all_tags.split(',').includes('production-design') : null
                })
                set_images_productiondesign(i_productiondesign)
                const i_vfx = mediaList.filter(o=>{
                    return o.all_tags ? o.all_tags.split(',').includes('vfx') : null
                })
                set_images_vfx(i_vfx)
                const i_costumes = mediaList.filter(o=>{
                    return o.all_tags ? o.all_tags.split(',').includes('costumes') : null
                })
                set_images_costumes(i_costumes)
                const i_other = mediaList.filter(o=>{
                    return o.all_tags ? (!o.all_tags.split(',').includes('hair-and-makeup') && !o.all_tags.split(',').includes('production-design') && !o.all_tags.split(',').includes('vfx') && !o.all_tags.split(',').includes('costumes')) : null
                })
                set_images_other(i_other)
            }

          }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mediaList, mediaSection ])

    return (
        <>

                <div className="">
                    <div className="p-3">

                        <div className="row justify-content-center">


                        {(mediaSection==='images' && images_hairandmakeup && Object.keys(images_hairandmakeup).length>0) ?
                            <>
                            <h2 className="text-uppercase text-center my-5 text-muted" style={{fontWeight:600,lineHeight:'1.2em',letterSpacing:'.4rem'}}>Hair & Makeup</h2>
                            <div className="row justify-content-center">
                            {images_hairandmakeup.map( (m, i) =>
                                <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                            </div>
                            </>
                        :null
                        }
                        {(mediaSection==='images' && images_productiondesign && Object.keys(images_productiondesign).length>0) ?
                            <>
                            <h2 className="text-uppercase text-center my-5 text-muted" style={{fontWeight:600,lineHeight:'1.2em',letterSpacing:'.4rem'}}>Production Design</h2>
                            <div className="row justify-content-center">
                            {images_productiondesign.map( (m, i) =>
                                <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                            </div>
                            </>
                        :null
                        }
                        {(mediaSection==='images' && images_vfx && Object.keys(images_vfx).length>0) ?
                            <>
                            <h2 className="text-uppercase text-center my-5 text-muted" style={{fontWeight:600,lineHeight:'1.2em',letterSpacing:'.4rem'}}>VFX</h2>
                            <div className="row justify-content-center">
                            {images_vfx.map( (m, i) =>
                                <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                            </div>
                            </>
                        :null
                        }
                        {(mediaSection==='images' && images_costumes && Object.keys(images_costumes).length>0) ?
                            <>
                            <h2 className="text-uppercase text-center my-5 text-muted" style={{fontWeight:600,lineHeight:'1.2em',letterSpacing:'.4rem'}}>Costumes</h2>
                            <div className="row justify-content-center">
                            {images_costumes.map( (m, i) =>
                                <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                            </div>
                            </>
                        :null
                        }
                        {(mediaSection==='images' && images_other && Object.keys(images_other).length>0) ?
                            <>
                            <h2 className="text-uppercase text-center my-5 text-muted" style={{fontWeight:600,lineHeight:'1.2em',letterSpacing:'.4rem'}}>Misc.</h2>
                            <div className="row justify-content-center">
                            {images_other.map( (m, i) =>
                                <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} /> )}
                            </div>
                            </>
                        :null
                        }


                        {
                            (mediaSection === 'press') ?
                                <div className="row justify-content-center">

                                    {
                                        mediaList ?
                                            mediaList.map( (m, i) =>
                                            <TitleMediaGridItem key={i} i={i} mediaItem={m} categorySlug={categorySlug} titleSlug={ titleSlug } mediaSection={mediaSection} />
                                            )
                                        : null
                                    }

                                </div>
                            :""
                        }

                        {
                            (mediaList && (mediaSection !== 'images') && (mediaSection !== 'press')) ?
                                mediaList.map( (m, i) =>
                                  <TitleMediaGridItem key={i} i={i} mediaItem={m} mediaSection={mediaSection} categorySlug={categorySlug} titleSlug={ titleSlug } /> )
                            :""
                        }

                        </div>

                    </div>
                </div>

        </>
    )
}

export default TitleMediaGrid
